import React, {FC} from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';
import { WeightChart } from 'components/charts/weight-chart';

import { useStyles } from './styles';

export const GraphBlock: FC<{ weightGoal: number, unit: string, weightPlan: number[], monthsPlan: string[], importantEventTitle: string }> = ({ weightGoal, unit, weightPlan, monthsPlan, importantEventTitle }) => {
    const { t } = useTranslation();

    const { graphWrapper, weightWrapper, preTitle, title, info } = useStyles();

    return (
        <div className={graphWrapper}>

            <Typography variant={TypographyVariants.h2} className={preTitle}>
                {t('pre-paywall-graph-block-pretitle')}
            </Typography>

            <Typography variant={TypographyVariants.h1} className={title}>
                {t('pre-paywall-graph-block-title')}
                <div className={weightWrapper}>
                    {weightGoal} {unit}
                </div>
                <br />
                {t('pre-paywall-graph-block-title-2')}{' '}
                {importantEventTitle}
            </Typography>

            <WeightChart
                weightPlan={weightPlan}
                monthsPlan={monthsPlan}
                unit={unit}
                goalWeight={weightGoal}
            />

            <Typography variant={TypographyVariants.h3} className={info}>
                {t('pre-paywall-graph-block-info')}
            </Typography>

        </div>
    );
};
