import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    modalWindowLoading: {
        zIndex: 102,
        backdropFilter: 'blur(2px)',
        '& > div': {
            padding: '24px',
        },
        '& h1': {
            margin: 'unset'
        }
    },
});
