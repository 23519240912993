import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    timerWrapper: {},
    nextButtonStyles: {
        ...nextButton.nextButton,
        background: '#36B992',
        margin: 0,

        '&:active': {
            ...nextButton.nextButtonHovered,
            background: '#36B992',
        }
    },
    '@media (min-width: 768px)': {
        timerWrapper: {

            '& > div': {
                width: '100%',
                maxWidth: 'none',

                '& > div:first-child > div > div': {
                    width: '550px',

                    '&::after': {
                        left: '48%'
                    }
                }
            }
        },
        nextButtonStyles: {
            margin: 'unset',
            maxWidth: '375px'
        },
    }
}));
