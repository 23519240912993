import React, { FC } from 'react';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

export interface TitleProps {
    number: number,
    text: string,
}

export const Title: FC<TitleProps> = ({ number, text }) => {

    const {
        programItem,
        circle
    } = useStyles();

    return (
        <div className={programItem}>
            <div className={circle}>{number}</div>
            <Typography variant={TypographyVariants.h1}>
                {text}
            </Typography>
        </div>
    );
};
