import React from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';
import { CheckPointsList } from 'components/check-points-list';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Title } from './title';
import { MedicalDirectorCard } from './medical-director-card';

import { useStyles } from './styles';

export const MedicationDescription = () => {
    const { t } = useTranslation();

    const {
        medicationDescriptionWrapper,
        title,
        medicationImage,
        medicationDescription,
        checkPointsListWrapper,
        checkPointText,
        divider,
        firstBlock,
        secondBlock,
        secondPointsWrapper,
        secondPointWrapper,
        thirdPointsWrapper,
        listWithCoachMessageWrapper,
        phoneImage
    } = useStyles();

    const images = [
        'webp/medication/medication-description/medication.webp',
        'svg/medication/medication-description/book.svg',
        'svg/medication/medication-description/try.svg',
        'svg/medication/medication-description/person.svg',
        'svg/medication/medication-description/clock.svg',
        'svg/medication/coach-message.webp',
    ];

    const pageImages = useBackgroundImageService(images);

    const block1 = [
        t('medication-medication-description-block-1-option-1'),
        t('medication-medication-description-block-1-option-2'),
        t('medication-medication-description-block-1-option-3'),
        t('medication-medication-description-block-1-option-4'),
    ];

    const block3 = [
        t('medication-medication-description-block-3-option-1'),
        t('medication-medication-description-block-3-option-2'),
    ];

    return (
        <div className={medicationDescriptionWrapper}>

            <Typography variant={TypographyVariants.h1} className={title}>
                {t('medication-medication-description-title')}
            </Typography>

            <div>
                <Title number={1} text={t('medication-medication-description-block-1-title')} />

                <div className={firstBlock}>
                    <img src={pageImages[0]} className={medicationImage} alt="Medication" />

                    <div>
                        <Typography variant={TypographyVariants.h2} className={medicationDescription}>
                            {t('medication-medication-description-block-1-text')}
                        </Typography>

                        <CheckPointsList items={block1} listItemClassName={checkPointsListWrapper} listItemTextClassName={checkPointText} />
                    </div>
                </div>
            </div>

            <div className={divider}></div>

            <div>
                <Title number={2} text={t('medication-medication-description-block-2-title')} />

                <div className={secondBlock}>
                    <div className={secondPointsWrapper}>
                        <Typography variant={TypographyVariants.h2} className={medicationDescription}>
                            {t('medication-medication-description-block-2-text')}
                        </Typography>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[1]} alt="Book"/>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-medication-description-block-2-option-1')}
                            </Typography>
                        </div>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[2]} alt="Try"/>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-medication-description-block-2-option-2')}
                            </Typography>
                        </div>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[3]} alt="Person"/>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-medication-description-block-2-option-3')}
                            </Typography>
                        </div>

                        <div className={secondPointWrapper}>
                            <img src={pageImages[4]} alt="Clock"/>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-medication-description-block-2-option-4')}
                            </Typography>
                        </div>
                    </div>

                    <MedicalDirectorCard />
                </div>
            </div>

            <div className={divider}></div>

            <div className={listWithCoachMessageWrapper}>
                <div>
                    <Title number={3} text={t('medication-medication-description-block-3-title')} />
                    <div className={thirdPointsWrapper}>
                        <CheckPointsList items={block3} listItemClassName={checkPointsListWrapper} listItemTextClassName={checkPointText} />
                    </div>
                </div>

                <img src={pageImages[5]} className={phoneImage} alt="Coach Message" />
            </div>
        </div>
    );
};
