import { createUseStyles } from 'react-jss';
import { HeaderStyles } from 'themes/default/header';

export const useStyles = createUseStyles({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        height: '100%',
        margin: '0 auto',
        padding: '24px 16px 32px'
    },
    checkoutContentWrapper: {
        margin: '0 auto',

        '& > div': {
            maxWidth: '560px',
            float: 'none',

            '& > p': {
                margin: '0 0 32px'
            }
        }
    },
    cartWrapper: {
        padding: '82px 56px',
        background: '#EAECF1',
        width: '100%'
    },
    shippingAddressFormDetailsWrapper: {
        marginTop: '16px',
    },
    paymentWrapper: {
        margin: '32px 0',
        display: 'none',
    },
    smallerMargin: {
        margin: '32px 0 16px',
    },
    paymentWrapperVisible: {
        display: 'block',
    },
    title: {
        margin: '0 0 16px',
        lineHeight: '24px',
        textAlign: 'left',
    },
    primerLoadingWrapper: {
        position: 'relative',

        '& div[class^="submitButtonWrapper"]': {
            pointerEvents: 'none',
            opacity: 0.3
        }
    },
    transparentContainer: {
        pointerEvents: 'none',

        '& #primerio-container': {
            opacity: 0.3
        }
    },
    '@media (min-width: 768px)' : {
        pageWrapper: {
            padding: 0
        },
        checkoutContentWrapper: {
            flexBasis: '100%',
            padding: '24px 56px 40px',

            '& > div': {
                float: 'right',

                '& > p': {
                    margin: '0 0 40px'
                }
            }
        },
        paymentWrapper: {
            margin: '40px 0',
        },
        smallerMargin: {
            margin: '40px 0 16px',
        },
        shippingAddressFormDetailsWrapper: {
            marginTop: '24px',
        },
        title: {
            margin: '0 0 24px',
        }
    },
    '@media (min-height: 900px)': {
        pageWrapper: {
            minHeight: `calc(100vh - (${HeaderStyles.header.height} + 119px))`, // 119px height of the footer
        }
    }
});
