import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import check from './check.svg';

import { useStyles } from './styles';

interface VerifiedFormProps {
    title: string,
    text: string,
    handleEdit: () => void;
}

export const VerifiedForm: FC<VerifiedFormProps> = ({ title, text, handleEdit }) => {
    const { t } = useTranslation();

    const { accountFormDetailsWrapper, blockTitle, infoWrapper, info, button, link } = useStyles();

    return (
        <div className={accountFormDetailsWrapper}>

            <div className={infoWrapper}>
                <Typography variant={TypographyVariants.h1} className={blockTitle}>
                    {title}
                </Typography>
                <img src={check} alt='Check mark' />
            </div>

            <div className={infoWrapper}>
                <Typography variant={TypographyVariants.h2} className={info}>
                    {text}
                </Typography>

                <button className={button} onClick={handleEdit}>
                    <Typography variant={TypographyVariants.h2} className={`${info} ${link}`}>
                        {t('checkout-verified-form-edit')}
                    </Typography>
                </button>
            </div>

        </div>
    );
};
