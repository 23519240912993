import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    medicalDirectorCardWrapper: {
        width: '65%',
        minWidth: '257px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
        padding: '8px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        marginTop: '16px',

        '& img': {
            width: '64px',
            borderRadius: '4px'
        }
    },
    medicalDirectorName: {
        margin: '0 0 8px 0',
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'unset',
    },
    medicalDirectorTitle: {
        margin: 'unset',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'unset',
    },
    '@media screen and (max-width: 280px)': { // Galaxy Fold
        medicalDirectorName: {
            fontSize: '10px',
            lineHeight: '12px',
        },
        medicalDirectorTitle: {
            fontSize: '10px',
            lineHeight: '14px',
        },
    },
    '@media (min-width: 768px)': {
        medicalDirectorCardWrapper: {
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
            textAlign: 'center',
            flexShrink: 0,
            width: '278px',
            minWidth: 'unset',
            padding: '12px',
            marginRight: '5px',
            marginTop: 0,
            gap: '16px',

            '& img': {
                width: '134px'
            }
        },
        medicalDirectorName: {
            fontSize: '18px',
            lineHeight: '22px',
        },
        medicalDirectorTitle: {
            fontSize: '16px',
            lineHeight: '20px',
        }
    }
});
