import React from 'react';

import { TipPage } from 'components/tip-page';

export const FamilyWeightTip = () => {

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/family-tree.svg'
            text={{
                description: 'family-weight-tip-eat-everything-description',
                next: 'family-weight-tip-button-next'
            }}
        />
    );
};
