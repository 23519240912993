import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    reviewsIOWrapper: {
        padding: '24px 0 12px',
        backgroundColor: '#F6F6F6',
    },
    wrapper: {},
    title: {
        fontSize: '24px',
        lineHeight: '34px',
        margin: '8px 24px 32px 24px',
        textAlign: 'left',
    },
    '@media (min-width: 768px)': {
        reviewsIOWrapper: {
            padding: '80px 0',
        },
        wrapper: {
            width: '60%',
            maxWidth: '1512px',
            margin: '0 auto',
        },
        title: {
            fontSize: '34px',
            lineHeight: '44px',
            textAlign: 'center',
            margin: '0 0 40px 0'
        }
    }
});
