import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    currentWeightWrapper: {
        background: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 6px',
        marginTop: '15px',
    },
    title: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '26px'
    },
    weightWrapper: {
        background: '#FFDAD2',
        borderRadius: '4px',
        padding: '0px 6px',
        display: 'inline-block',
        marginLeft: '8px',
    },
    subTitle: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        margin: '16px 0 12px',
    },
    goalBlock: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '8px',
        '& img': {
            width: '16px',
        }
    },
    goalItem: {
        margin: 'unset',
        textAlign: 'unset',
        whiteSpace: 'initial',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    '@media (min-width: 768px)': {
        currentWeightWrapper: {
            marginTop: '40px',
            padding: '24px 0px 12px',
        },
        title: {
            fontSize: '22px',
            lineHeight: '28px'
        },
        subTitle: {
            fontSize: '14px',
            lineHeight: '18px',
            margin: '24px 0 12px',
        },
        goalBlock: {
            gap: '16px',
            marginBottom: '12px',
        },
        goalItem: {
            fontSize: '18px',
            lineHeight: '26px',
        }
    }
});
