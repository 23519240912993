import React from 'react';

import { TipPage } from 'components/tip-page';

import { useStyles } from './styles';

export const PrescriptionMedicationsTip = () => {
    const { title, listStyle } = useStyles();

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/medications.svg'
            text={{
                description: 'prescription-medications-tip-description',
                components: [
                    <strong className={title}></strong>,
                    <strong></strong>,
                    <ul className={listStyle}>{[''].map(item => (
                        <li key={item}>
                            {item}
                        </li>
                    ))}
                    </ul>
                ],
                next: 'prescription-medications-tip-button-next'
            }}
        />
    );
};
