import React from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export const Rating = () => {

    const {
        ratingWrapper,
        applicationsWrapper,
        appRating,
        googleRating,

        divider,

        journalsLogos,
        womenHealthIcon,
        rating,
        forbesIcon,
        yahooIcon
    } = useStyles();

    const items = [
        'svg/medication/rating/app.svg',
        'svg/medication/rating/google.svg',
        'svg/medication/rating/rating.svg',
        'svg/medication/rating/women-health.svg',
        'svg/medication/rating/forbes.svg',
        'svg/medication/rating/yahoo.svg',
    ];
    const pageImages = useBackgroundImageService(items);

    return (
        <div className={ratingWrapper}>
            <div className={applicationsWrapper}>
                <img className={appRating} src={pageImages[0]} alt={'Able App Rating'} />
                <img className={googleRating} src={pageImages[1]} alt={'Play Rating'} />
                <img className={rating} src={pageImages[2]} alt={'Rating'} />
            </div>
            <div className={divider}></div>
            <div className={journalsLogos}>
                <img className={womenHealthIcon} src={pageImages[3]}
                     alt={'Women Health Journal Logo'} />
                <img className={forbesIcon} src={pageImages[4]} alt={'Forbes Journal Logo'} />
                <img className={yahooIcon} src={pageImages[5]} alt={'Yahoo Journal Logo'} />
            </div>
        </div>
    );
};
