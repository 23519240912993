import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        marginBottom: '16px'
    },
    subtitle: {
        margin: '0 0 16px',
    },
    listWrapper: {
        marginBottom: '12px'
    },
    listItem: {
        display: 'flex',
        marginBottom: '16px',

        '&:last-child': {
            marginBottom: '12px',
        },

        '& img': {
            alignSelf: 'flex-start'
        }
    },
    listItemText: {
        textAlign: 'left',
        marginLeft: '12px',
        fontSize: '18px',
        lineHeight: '26px',
    },
    link: {
        textUnderlineOffset: '4px',
        color: '#010101'
    },
    '@media (min-width: 768px)' : {
        title: {
            marginBottom: '32px'
        },
        subtitle: {
            margin: '0 0 32px'
        },
        listWrapper: {
            marginBottom: '56px'
        }
    }
});
