import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const WeightFactors = () => {
    const { t } = useTranslation();

    const {
        title,
        subtitle,
        weightFactorsIllustrationWrapper
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = ['svg/weight-factors/weight-factors-illustration.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title} typographyNext>
                <Trans i18nKey='weight-factors-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='weight-factors-description' components={[<strong></strong>]} />
            </Typography>

            <div className={weightFactorsIllustrationWrapper}>
                <img src={pageImages[0]} alt='Weight Factors Illustration' />
            </div>

            <NextButton typographyText={t('weight-factors-button-next')} onClick={handleClick} />
        </>
    );
};
