import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const WeightLoss = () => {
    const { t } = useTranslation();

    const {
        title,
        subtitle,
        graphicTitle,
        graphicImage,
        graphicSubTitleWrapper,
        graphicSubTitle
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = ['svg/weight-loss/weight-loss-graphic.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title} typographyNext>
                <Trans i18nKey='weight-loss-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='weight-loss-subtitle' components={[<strong></strong>]} />
            </Typography>

            <div>
                <Typography variant={TypographyVariants.h2} className={graphicTitle}>
                    {t('weight-loss-image-chart-title')}
                </Typography>
                <img src={pageImages[0]} className={graphicImage} alt='Weight Loss Chart' />
                <div className={graphicSubTitleWrapper}>
                    <Typography variant={TypographyVariants.h3} className={graphicSubTitle}>
                        {t('weight-loss-month-one')}
                    </Typography>
                    <Typography variant={TypographyVariants.h3} className={graphicSubTitle}>
                        {t('weight-loss-month-six')}
                    </Typography>
                </div>
            </div>

            <NextButton typographyText={t('weight-loss-button-next')} onClick={handleClick} />
        </>
    );
};
