import React from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

type CheckPointsListProps = {
    items: string[];
    listItemClassName?: string;
    listItemTextClassName?: string;
    listItemTextComponents?: JSX.Element[];
}

export const CheckPointsList = ({ items, listItemClassName, listItemTextClassName, listItemTextComponents }: CheckPointsListProps): JSX.Element => {
    const imagesItems = ['svg/check-icon.svg'];
    const pageImages = useBackgroundImageService(imagesItems);

    return (
        <>
            {
                items.map((item, index) => {
                    return (
                        <div className={listItemClassName} key={index}>
                            <img src={pageImages[0]} alt='Check Icon' />
                            <Typography className={listItemTextClassName} variant={TypographyVariants.h3}>
                                <Trans i18nKey={item} components={listItemTextComponents} />
                            </Typography>
                        </div>
                    );
                })
            }
        </>
    );
};
