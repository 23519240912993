import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const PastAttempts = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PastAttempts];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'fitness-trackers',
            value: t('past-attempts-option-fitness-trackers')
        },
        {
            key: 'calorie-counting-apps',
            value: t('past-attempts-option-calorie-counting-apps')
        },
        {
            key: 'gym-exercise',
            value: t('past-attempts-option-gym-exercise')
        },
        {
            key: 'meal-planning',
            value: t('past-attempts-option-meal-planning')
        },
        {
            key: 'restrictive-dieting',
            value: t('past-attempts-option-restrictive-dieting')
        },
        {
            key: 'nutritionist-dietician',
            value: t('past-attempts-option-nutritionist-dietician')
        },
        {
            key: 'prescription-medication',
            value: t('past-attempts-option-prescription-medication')
        },
        {
            key: 'food-delivery',
            value: t('past-attempts-option-food-delivery'),
        },
        {
            key: 'professional-therapy',
            value: t('past-attempts-option-professional-therapy'),
        },
        {
            key: 'none',
            value: t('past-attempts-option-none'),
        }
    ];

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('past-attempts-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                {t('past-attempts-title')}
            </Typography>


            <Typography variant={TypographyVariants.h2}>
                {t('past-attempts-subtitle')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
