import React, { FC } from 'react';

import { BreadcrumbsNavigationKeys } from 'constants/breadcrumbs-navigation';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

interface BreadcrumbsNavigationProps {
    activeSection: string;
}

export const BreadcrumbsNavigation: FC<BreadcrumbsNavigationProps> = ({ activeSection }) => {
    const { sectionsList, listItem, sectionName, active } = useStyles();

    const items = ['svg/checkout/breadcrumbs-navigation/navigation-icon.svg'];
    const pageImages = useBackgroundImageService(items);

    const sections = [
        BreadcrumbsNavigationKeys.quiz,
        BreadcrumbsNavigationKeys.healthPlan,
        BreadcrumbsNavigationKeys.account,
        BreadcrumbsNavigationKeys.checkout
    ];

    return (
        <nav>
            <ol className={sectionsList}>
                {sections.map(section =>
                    <li className={listItem} key={section}>
                        <Typography variant={TypographyVariants.h3}
                                    className={`${sectionName} ${activeSection === section && active}`}>
                            {section}
                        </Typography>
                        <img src={pageImages[0]} alt='Arrow Icon' />
                    </li>
                )}
            </ol>
        </nav>
    );
};
