import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        maxWidth: '506px',
        height: 'auto',
        marginBottom: '8px',
    },
    infoText: {
        marginTop: '8px',
    },
    '@media (min-width: 768px)' : {
        image: {
            marginBottom: '16px',
        },
        infoText: {
            marginTop: '16px',
        }
    }
});
