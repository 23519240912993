import React, { FC } from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export interface VideoPlayerProps {
    videoId: string;
    onClose: () => void;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoId, onClose }) => {
    const { videoPlayerWrapper, videoPlayerCrossIcon } = useStyles();

    const imgSrc = ['svg/medication/video-player/cross.svg'];
    const pageImages = useBackgroundImageService(imgSrc);

    return (
        <div className={videoPlayerWrapper} onClick={onClose}>
            <img className={videoPlayerCrossIcon} src={pageImages[0]} alt='Cross Icon' />
            <iframe width="640px"
                    height="480px"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
            </iframe>
        </div>
    );
}
