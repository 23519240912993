import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    title: {

        '@media (min-width: 768px)' : {
            fontSize: '24px',
            lineHeight: '28px',
            margin: '80px 0 40px'
        }
    },
    titleHighlight: {
        background: '#CAF3E7',
        padding: '0 2px',

        '@media (min-width: 768px)' : {
            display: 'inline-block',
            marginTop: '12px'
        }
    },
    weightLossWithAbleChartWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid #C5C5D1',
        borderRadius: '8px',
        padding: '16px 24px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '32px',
        maxWidth: '358px',

        '@media (min-width: 768px)' : {
            marginBottom: '40px'
        }
    },
    weightLossWithAbleChartTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '80%',
        marginBottom: '16px'
    },
    weightLossWithAbleChartTitle: {
        lineHeight: '15px',
        fontWeight: 600,
        margin: 'unset'
    },
    weightLossWithAbleChartTitleValueWrapper: {
        display: 'flex',
        alignItems: 'baseline'
    },
    weightLossWithAbleChartTitleValue: {
        fontSize: '36px',
        lineHeight: '25px',
        color: '#2B9475',
        margin: 'unset'
    },
    partyIcon: {
        height: '30px',
        width: '30px',
        marginLeft: '8px'
    },
    weightLossWithAbleChartInfo: {
        lineHeight: '22px',
        color: '#5E626B',
        textAlign: 'left',
        marginTop: '16px'
    },
    progressBarTitle: {
        margin: '0 0 12px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    bar: {
        height: '48px !important',
        maxWidth: '358px',
        borderRadius: '8px',
        margin: '0 auto',

        '& > div': {
            borderRadius: '8px'
        }
    },
    '@media screen and (max-width: 500px)': {
        weightLossWithAbleChartTitleWrapper: {
            width: '85%',
        }
    },
    '@media screen and (max-width: 320px)': { // iPhone 4/5 resolution
        weightLossWithAbleChartTitleWrapper: {
            width: '90%',
        }
    },
});
