import { Palette } from '../index';

export type ChoiceButtonType = {
    choiceButton: {
        border: string,
        width: string,
        minWidth: string,
        maxWidth: string,
        minHeight: string,
        height: string,
        margin: string,
        padding: string
    } & { [key: string]: {} },
    choiceButtonSelected: {
        background: Palette | string
        color: Palette | string,
    },
    choiceButtonActive?: {
        boxShadow: string,
    },
    choiceButtonHovered?: {
        background: string,
        color?: string,
    }
}

export const ChoiceButtonStyles: ChoiceButtonType = {
    choiceButton: {
        border: 'none',
        minWidth: '250px',
        width: '100%',
        maxWidth: '560px',
        minHeight: '56px',
        height: 'auto',
        margin: '0 0 12px',
        padding: '16px',

        '@media only screen and (min-width: 768px)' : {
            margin: '0 0 16px'
        }
    },
    choiceButtonHovered: {
        background: '#B7E3FF',
        color: '#010101',
    },
    choiceButtonSelected: {
        background: '#1375D6',
        color: '#FFFFFF',
    },
    choiceButtonActive: {
        boxShadow: 'none',
    }
};
