import dayjs from 'dayjs';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { getUnitSystem } from 'constants/body-height-weight';
import { ABLE_PLAN } from 'constants/able-plan';
import { GtagKeys } from 'constants/analytics';
import {
    APP_NAME,
    APP_SOURCE_DESKTOP,
    APP_SOURCE_MOBILE,
    APP_TYPE_APP,
    APP_TYPE_PWA,
} from 'constants/app-name';
import { IntakeFormKey } from 'constants/intake-form';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { AGES, USA_COUNTRY_CODE} from 'constants/customer';

import { getLocalStorageData } from './local-storage-data';
import { getCookie } from './cookie';
import { convertToCm, convertToKgWithoutRounding } from './unit-converter';
import { detectBrowser, detectPlatform, isDesktop } from 'helpers/detect-device-type';

import { getCustomerDetails } from 'pages/checkout/account-form';
import { getShippingAddressDetails } from 'pages/checkout/shipping-address-form';

const getBodyHeight = () => {
    const heightFt = localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt);
    const heightInches = localStorage.getItem(
        MultipleLocalStorageKeys.bodyHeightWeightKeys.heightInches
    );

    // @description: Always send data in metric system
    const height = convertToCm(Number(heightFt), Number(heightInches));

    return Number(height);
};

const getBodyWeightCurrent = () => {
    // @description: Always send data in metric system
    const weight = convertToKgWithoutRounding(
        Number(localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs))
    );

    return weight;
};

const getBodyWeightGoal = (weightGoal: string) => {
    // @description: Always send data in metric system
    const weight = convertToKgWithoutRounding(Number(weightGoal));

    return weight;
};

const getSignUpDataFromLocalStorage = () => {
    const localStorageData = getLocalStorageData(Object.values(LocalStorageKeys));

    return {
        ...localStorageData,
        height: getBodyHeight(),
        weightCurrent: getBodyWeightCurrent(),
    };
};

export const getCid = () => {
    const local = localStorage.getItem('cid');
    const cookie = getCookie('cid');
    const session = sessionStorage.getItem('cid');

    return local || cookie || session;
};

// @param: additionalData - object with post data not related to local storage
export const getSignUpData = (paymentData: any = {}) => {
    const customer = getCustomerDetails();
    const shippingAddress = getShippingAddressDetails();

    const data = getSignUpDataFromLocalStorage();
    const unitSystem = getUnitSystem();

    const pwaNativeSplitVariationExp = true;

    data.plan = ABLE_PLAN;

    data.weightGoal = getBodyWeightGoal(data.weightGoal);

    data.languageCode =
        window.navigator.language?.toLowerCase() ||
        window.navigator.userLanguage?.toLowerCase() ||
        'en';

    const questions = JSON.parse(localStorage.getItem(IntakeFormKey) as string);
    data.intakeForm = questions ? Object.values(questions) : [];

    data.cid = getCid();
    data.gclid = localStorage.getItem('gclid') || '';
    data.userID = localStorage.getItem('userID');
    data.clickID = localStorage.getItem('clickId') || '';
    data.advertisingID = localStorage.getItem('userID'); // @TODO: why they are the same?
    data.kountSessionID = localStorage.getItem('kount_session_id') || '';
    data.gtagClientId = localStorage.getItem(GtagKeys.gtagClientId);
    data.jsonMetadata = localStorage.getItem('json_metadata');
    data.appName = `${APP_NAME} ${ABLE_PLAN}`;
    data.subdomain = window.location.hostname.split('.').slice(0, -2).join('.') || '-';
    data.type = 'Primer.io';
    data.platform = isDesktop() ? `desktop: ${detectBrowser()}` : detectPlatform();
    data.source = isDesktop() ? APP_SOURCE_DESKTOP : APP_SOURCE_MOBILE;
    data.appType = pwaNativeSplitVariationExp ? APP_TYPE_PWA : APP_TYPE_APP;
    data.medusaBundleID = paymentData.medusaBundleID;

    data.unit = unitSystem;
    data.unitWeight = unitSystem;
    data.unitHeight = unitSystem;

    const dateDiff = dayjs().diff(customer.dateOfBirth, 'year');
    data.age = AGES.find(({ min, max }) => min <= dateDiff && max >= dateDiff)?.age || '';

    customer.phone = `${USA_COUNTRY_CODE}${customer.phone}`;
    data.customer = customer;
    data.shippingAddress = shippingAddress;

    data.country = localStorage.getItem(UserGeolocationKeys.countryCode);
    data.timezone = localStorage.getItem(UserGeolocationKeys.timezone);

    return {
        ...data,
        ...paymentData,
    };
};
