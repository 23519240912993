import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    submitButtonWrapper: {
        textAlign: 'center',
        '& button': {
            marginTop: '0px',
            display: 'none'
        },
        '& $buttonVisible': {
            display: 'inline'
        }
    },
    buttonVisible: {
        display: 'inline',
    },
    continueButton: {
        marginTop: '32px',
        background: '#36B992',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        position: 'relative',
        padding: '12px 16px',
        maxWidth: '400px',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '18px',
        minWidth: '250px',
        width: '100%',
        height: '48px',
        color: '#FFFFFF',

        '&:focus': {
            background: '#2B9475',
        },
        '&:hover': {
            background: '#31A783',
        },
    },
    '@media (min-width: 768px)': {
        continueButton: {
            maxWidth: '343px',
            alignSelf: 'center',
            border: 'none',
            marginTop: '40px',
        }
    },
    '@media (max-width: 280px)': {
        continueButton: {
            minWidth: '200px',
        }
    },
});
