import React from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

export const MedicalDirectorCard = () => {

    const {
        medicalDirectorCardWrapper,
        medicalDirectorName,
        medicalDirectorTitle
    } = useStyles();

    const pageImages = useBackgroundImageService(['svg/medication/doctor.webp']);

    return (
        <div className={medicalDirectorCardWrapper}>
            <img src={pageImages[0]} alt='Medical Director' />
            <div>
                <Typography variant={TypographyVariants.h1} className={medicalDirectorName}>
                    Dr. Arvind Chakravarthy
                </Typography>
                <Typography variant={TypographyVariants.h2} className={medicalDirectorTitle}>
                    Medical Director
                    <br />
                    MD, MBA
                </Typography>
            </div>
        </div>
    );
};
