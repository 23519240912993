import React from 'react';
import { useTranslation } from 'react-i18next';

import { MedicationAccordion } from '../medication-accordion';

export const Expectations = () => {

    const { t } = useTranslation();

    const items = [
        {
            id: 1,
            title: t('medication-expectations-item-1-title'),
            subtitle: t('medication-expectations-item-1-subtitle'),
            items: [
                t('medication-expectations-item-1-option-1'),
                t('medication-expectations-item-1-option-2'),
                t('medication-expectations-item-1-option-3')
            ]
        },
        {
            id: 2,
            title: t('medication-expectations-item-2-title'),
            subtitle: t('medication-expectations-item-2-subtitle'),
            items: [
                t('medication-expectations-item-2-option-1'),
                t('medication-expectations-item-2-option-2'),
                t('medication-expectations-item-2-option-3')
            ]
        },
        {
            id: 3,
            title: t('medication-expectations-item-3-title'),
            subtitle: t('medication-expectations-item-3-subtitle'),
            items: [
                t('medication-expectations-item-3-option-1'),
                t('medication-expectations-item-3-option-2'),
                t('medication-expectations-item-3-option-3')
            ]
        },
        {
            id: 4,
            title: t('medication-expectations-item-4-title'),
            subtitle: t('medication-expectations-item-4-subtitle'),
            items: [
                t('medication-expectations-item-4-option-1'),
                t('medication-expectations-item-4-option-2'),
                t('medication-expectations-item-4-option-3'),
                t('medication-expectations-item-4-option-4')
            ]
        }
    ];

    return (
        <MedicationAccordion title={t('medication-expectations-title')} listedItems={items} />
    );
};
