import React, { FC } from 'react';

import { LinksKeys } from 'constants/links';

import { useStyles } from './styles';

export interface SecondaryFooterProps {}

export const Footer: FC<SecondaryFooterProps> = (): JSX.Element => {
    const {
        footer,
        footerContent,
        links,
        contentBlock,
        companyInfo
    } = useStyles();

    const userPolicyLinksList =
        <>
            <a href={LinksKeys.TermsAndCondition} target='_blank' rel='noreferrer'>Terms and Conditions</a>
            <a href={LinksKeys.PrivacyPolicy} target='_blank' rel='noreferrer'>Privacy Policy</a>
            <a href={LinksKeys.RefundPolicy} target='_blank' rel='noreferrer'>Refund Policy</a>
        </>;

    const companyAddress = '353 Kearny St, San Francisco, CA 94108';
    const companyPhoneNumber = '(646) 810-5749';

    return (
        <footer className={footer}>
            <div className={footerContent}>
                <div className={contentBlock}>
                    <div className={companyInfo}>
                        <span>{companyAddress}</span>
                        <span>{companyPhoneNumber}</span>
                    </div>
                    <div className={links}>
                        {userPolicyLinksList}
                    </div>
                </div>
            </div>
        </footer>
    );
};
