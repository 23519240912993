import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

interface ShippingStatesProps {
    statesNames: string;
}

export const ShippingStates: FC<ShippingStatesProps> = ({ statesNames }) => {
    const { t } = useTranslation();
    const {
        statesInfoWrapper,
        truncated,
        chevron,
        icon,
        rotate
    } = useStyles();

    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    const maxLines = expanded ? 'none' : 2;

    return (
        <div className={`${statesInfoWrapper} ${!expanded ? truncated : ''}`}>
            <Typography variant={TypographyVariants.h3} style={{ WebkitLineClamp: maxLines }}>
                {t('checkout-shipping-address-states-info', {statesNames})}
            </Typography>
            <div className={chevron} onClick={toggleExpansion}>
                <span className={`${icon} ${expanded ? rotate : ''}`}></span>
            </div>
        </div>
    );
};
