import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const Congrats = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const pwaNativeSplitVariationExp = true;

    useEffect(() => {
        setTimeout(() => pwaNativeSplitVariationExp ? handleRedirectToPwa() : pageConfiguration.handleOnPageComplete(), 4000);
    }, [navigate]);

    const { content, title, text } = useStyles();

    return (
        <div>
            <div className={content}>
                <Typography variant={TypographyVariants.h1} className={title}>
                    {t('congrats-title')}
                </Typography>
                <Typography variant={TypographyVariants.h2} className={text}>
                    <Trans i18nKey="congrats-description" />
                </Typography>
            </div>
        </div>
    );
};
