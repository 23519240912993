import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { useStyles } from './styles';

export type CustomerCardType = {
    name: string;
    backgroundPhoto: string;
    review: string;
};

type CustomerCardProps = {
    customerCard: CustomerCardType;
    setCurrentVideoName: (value: string | null) => void;
};

export const CustomerCard: FC<CustomerCardProps> = ({ customerCard, setCurrentVideoName }) => {
    const { t } = useTranslation();

    const { name, review } = customerCard;
    const {
        customerCardWrapper,
        customerName,
        customerWeightLoss,
    } = useStyles();

    const items = ['svg/medication/video-player/play.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <div className={customerCardWrapper} onClick={() => setCurrentVideoName(name)}>
            <img src={pageImages[0]} alt={'Play Icon'} />
            <Typography className={customerName} variant={TypographyVariants.h3}>
                {t(name)}
            </Typography>
            <Typography className={customerWeightLoss} variant={TypographyVariants.h3}>
                {t(review)}
            </Typography>
        </div>
    );
};
