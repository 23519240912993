import { MedusaApi } from 'api/medusa.api';
import { useEffect, useState } from 'react';
import { Bundle, Product } from 'types/medusajs';

const parseBundles = (bundles: Bundle[]) =>
    bundles.map((bundle) => ({
        bundleId: bundle.id,
        planId: bundle.planId,
        key: bundle.planCode,
        title: bundle.title,
        description: bundle.description,
        price: bundle.priceAndPeriod.price,
        periodDays: bundle.priceAndPeriod.periodDays,
        trialPrice: bundle.priceAndPeriod.trialPrice,
        trialPeriodDays: bundle.priceAndPeriod.trialPeriodDays,
        chargePeriodUnit: bundle.priceAndPeriod.chargePeriodUnit,
        currency: bundle.priceAndPeriod.currency,
        discountAmount: bundle.priceAndPeriod.discountAmount,
        discountType: bundle.priceAndPeriod.discountType,
        effectivePrice: bundle.priceAndPeriod.effectivePrice,
        effectiveTrialPrice: bundle.priceAndPeriod.effectiveTrialPrice
    }));

function useMedusaProducts() {
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchProducts = async () => {
        setIsLoading(true);
        try {
            const data = await MedusaApi.getBundles();

            if (data?.bundles?.length) {
                setProducts(parseBundles(data?.bundles));
            }
        } catch (error) {
            console.error('Error fetching bundles:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return { products, isLoading };
}

export default useMedusaProducts;
