import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    tipPageWrapper: {
        background: '#F6F6F6',
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1px',

        '& > h1': {
            fontSize: '18px',
            lineHeight: '26px',
            margin: '8px 0 0'
        },

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            width: 'auto',
            maxWidth: '151px',
            minWidth: '151px',
            padding: '5px',

            '& > img': {
                width: '56px',
            },

            '& > div > h1': {
                fontSize: '12px',
                margin: '0 0 4px',
                whiteSpace: 'nowrap'
            },

            '& > div > h1, h2': {
                textAlign: 'center'
            }
        }
    },
    childrenWrapper: {
        maxWidth: '608px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0px 16px 0px',
        margin: '0px auto'
    },
    button: {
        padding: '4px 16px 0px',
    },
    '@media (min-width: 768px)': {
        headerWrapper: {
            marginBottom: '-16px',

            '& > h1': {
                fontSize: '22px',
                margin: '40px 0 0'
            },

            '& > div': {
                gap: '12px',
                maxWidth: '158px',
                minWidth: '158px',
                padding: '8px',
                marginTop: '24px',

                '& > img': {
                    width: '91px',
                },

                '& > div > h1': {
                    margin: '0 0 8px',
                },
            }
        },
        childrenWrapper: {
            padding: '0px'
        },
        button: {
            position: 'static',
            padding: '0px 16px'
        }
    }
});
