import { RoutePath } from 'routes/route-path.constant';

export const LocalStorageKeys = {
    [RoutePath.BodyGender]: 'gender',
    [RoutePath.LoseWeight]: 'loseWeight',
    [RoutePath.FamilyWeight]: 'familyWeight',
    [RoutePath.PastAttempts]: 'pastAttempts',
    [RoutePath.LoseWeightReason]: 'loseWeightReason',
    [RoutePath.PrescriptionMedications]: 'prescriptionMedications',
    [RoutePath.BodyIdealWeight]: 'weightGoal',
    [RoutePath.HealthCondition]: 'healthCondition',
    [RoutePath.MedicalCondition]: 'medicalCondition',
    [RoutePath.MedicationIntake]: 'medicationIntake',
    [RoutePath.ImportantEvent]: 'important-event',
    [RoutePath.ImportantEventDate]: 'important-event-date',
    [RoutePath.Email]: 'email',
    [RoutePath.Medication]: 'plan',
};

export const MultipleLocalStorageKeys = {
    bodyHeightWeightKeys: {
        unitSystem: 'body-height-weight-unitSystem',
        heightFt: 'body-height-ft',
        heightInches: 'body-height-inches',
        weightLbs: 'body-weight-lbs',
        bmi: 'body-height-weight-bmi'
    },
    [RoutePath.Checkout]: {
        firstName: 'customer-first-name',
        lastName: 'customer-last-name',
        password: 'customer-password',
        dateOfBirth: 'customer-date-of-birth',
        phone: 'customer-phone',
        streetAddress: 'customer-street-address',
        address2: 'customer-address-2',
        city: 'customer-city',
        state: 'customer-state',
        zipCode: 'customer-zip-code',
        fullAddress: 'customer-full-address',
        googleValidatorFormattedAddress: 'customer-google-validator-formatted-address',
    }
};
