import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { RoutePath } from 'routes/route-path.constant';

import { useImportantDateCalculation } from 'hooks/use-important-date-calc.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.rx.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import rightArrow from 'assets/images/svg/important-event-popover/right-arrow.svg';

import { useStyles } from './styles';

export const ImportantEventPopover = () => {
    const { t } = useTranslation();

    const {
        popoverWrapper,
        dotSymbol,
        text,
        futureText,

        l_p_3_0, l_p_3_1,
        l_p_6_1, l_p_6_3, l_p_6_4,
    } = useStyles();

    const eventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]);
    const event = eventValue === 'other' ? t('important-event-date-other-option-event-name') : eventValue;
    const eventDate = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEventDate]) as string;

    let { unit, finalDate, weightLbs, weightsPlan, monthsPlan, monthsPlanForDisplay } = useBodyPlanData();

    const {
        isFutureDate,
        weightAtEventDate,
        isToday,
        isWithinWeek,
        positionForDisplay,
    } = useImportantDateCalculation(eventDate, monthsPlan, weightsPlan, monthsPlanForDisplay);

    const [sectionClass, setSectionClass] = useState('');

    useEffect(() => {

        setSectionClass([l_p_3_0, l_p_6_1, l_p_3_1, l_p_6_3, l_p_6_4, l_p_6_4][positionForDisplay]);

    }, [positionForDisplay]);

    const isEventInFuture = isFutureDate && (dayjs(new Date(eventDate))).isAfter(finalDate);

    const getWeightToShow = () => {
        const weightToSubtract = 1;
        const adjustedWeight = weightAtEventDate - weightToSubtract;

        let weightToShow;
        if (isToday) {
            weightToShow = weightLbs;
        } else if (isWithinWeek) {
            weightToShow = weightLbs - weightToSubtract;
        } else if (weightAtEventDate === weightLbs) {
            weightToShow = adjustedWeight;
        } else {
            weightToShow = weightAtEventDate;
        }

        return weightToShow;
    };

    return (
        <div
            className={`${popoverWrapper} ${sectionClass} ${isEventInFuture && futureText}`}>
            {!isEventInFuture && <span className={dotSymbol}></span>}
            <span className={text}>
                {event} ({getWeightToShow()}&nbsp;{unit}) {isEventInFuture && <img src={rightArrow} alt='Arrow' />}
            </span>
        </div>
    );
};
