import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ input, typography }: ThemeProps) => ({
    textAreaInputWrapper: {
        height: '100%',
        minHeight: '100px',
        background: '#F6F6F6',
        borderRadius: '8px',
        margin: '0 0 8px',
        padding: '8px 16px'
    },
    textAreaInput: {
        ...input.input,
        marginTop: '12px',
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#010101'
    },
    textAreaInputActiveClass: input.inputActiveClass,
    textAreaInputLabel: {
        ...typography.inputLabel,
        fontSize: '14px',
        lineHeight: '18px',
        margin: '8px 0 0',
        top: 'unset',
        transform: 'translateY(0px)',
    },
    textAreaInputLabelActive: {
        ...typography.inputLabelActive,
        fontSize: '12px',
        lineHeight: '16px',
        top: 'unset',
        transform: 'translateY(-4px)',
    },
    charactersCounterStyles: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#5E626B',
        textAlign: 'right',
        margin: '0 0 12px',

        '@media (min-width: 768px)' : {
            margin: '0 0 16px',
        }
    }
}));

