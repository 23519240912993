import React from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { Checkbox } from 'components/checkbox';
import { ChoiceButton } from 'components/choice-button';
import { TextAreaInput } from 'components/text-area-input';

import { MAX_CHARACTERS } from 'constants/text-area-input';

import { useStyles } from './styles';

export type MultiChoiceType = { key: string; value: string; image?: string; userAnswer?: string, setUserAnswer?: any; };
type MultiChoiceButtonType = { item: MultiChoiceType, pageValue: string[], setPageValue: (i: string[]) => void, disabled?: boolean, textAreaInputLabel?: string };

export const MultiChoiceButton = ({ item, pageValue, setPageValue, disabled, textAreaInputLabel = '' }: MultiChoiceButtonType) => {
    const { multiChoiceButton, multiChoiceButtonSelected, multiChoiceButtonDisabled, imageClass } = useStyles();

    // Remove item or add
    const buttonClicked = () => {
        if (!Array.isArray(pageValue)) {
            pageValue = [];
        }

        if (item.key === 'other') {
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
        }

        let newPageValue = [...pageValue];

        //@description: Deselect all values or remove 'None' value;
        if (['none', 'all'].includes(item.key)) {
            newPageValue = [];
        } else {
            //@TODO: Think about it. Hack for now
            newPageValue = pageValue.filter((i: string) => !['none', 'all'].includes(i));
        }

        if (pageValue.includes(item.key)) {
            newPageValue = pageValue.filter((i: string) => i !== item.key);
        } else {
            newPageValue.push(item.key);
        }

        setPageValue(newPageValue);
    };

    const pageImages = useBackgroundImageService([item?.image as string]);

    const selectedItem = pageValue.includes(item.key);

    const maxCharacters = MAX_CHARACTERS;
    const textAreaInputShown = selectedItem && item.setUserAnswer;

    return (
        <>
            <ChoiceButton
                selected={selectedItem}
                className={`${multiChoiceButton} ${selectedItem && multiChoiceButtonSelected} ${disabled && multiChoiceButtonDisabled}`}
                typographyVariant={TypographyVariants.multiChoiceButton}
                onClick={buttonClicked}
            >
                <Checkbox checked={selectedItem} />

                <Typography variant={TypographyVariants.multiChoiceButton}>{item.value}</Typography>

                {pageImages[0] && <img className={imageClass} src={pageImages[0]} alt={item.key} />}
            </ChoiceButton>
            {textAreaInputShown && <TextAreaInput onChange={item?.setUserAnswer}
                                                  value={item.userAnswer as string}
                                                  maxCharacters={maxCharacters}
                                                  label={textAreaInputLabel} />
            }
        </>
    );
};
