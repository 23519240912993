import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import useMedusaProducts from 'hooks/use-medusa-products';

import { TypographyVariants } from 'constants/typography-variants';

import { Product } from 'types/medusajs';

import { Typography } from 'components/typography';

import { RadioButton } from './radio-button';

import { useStyles } from './styles';

interface MedicationProgramSelectorProps {
    plan: Product;
    setPlan: (value: Product) => void;
}

export const MedicationProgramSelector: FC<MedicationProgramSelectorProps> = ({
    plan,
    setPlan,
}) => {
    const { contentWrapper } = useStyles();

    const { products } = useMedusaProducts();

    return (
        <div className={contentWrapper}>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey="medication-program-choose-medication-plan-title" />
            </Typography>

            {products.map((product) => (
                <RadioButton
                    key={product.key}
                    title={product.title}
                    description={product?.description || ''}
                    checked={plan?.effectivePrice === product?.effectivePrice}
                    onClick={() => {
                        setPlan(product);
                    }}
                    effectivePrice={product?.effectivePrice / 100}
                />
            ))}
        </div>
    );
};
