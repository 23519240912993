import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    numbersClass: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: '8px',
        marginTop: '5px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B'
    },
    monthClass: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '7%',
        paddingTop: '8px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B',
        height: '25px',
    },
    weightGraphic: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '& svg': {
            display: 'block',
        }
    },
    popoverWrapper: {
        top: '74%',
        left: '81%'
    },
    '@media (max-width: 540px)' : {
        popoverWrapper: {
            top: '73%'
        }
    },
    '@media (max-width: 530px)' : {
        popoverWrapper: {
            top: '73%',
            left: '80.5%',
        }
    },
    '@media (max-width: 500px)' : {
        popoverWrapper: {
            top: '71%',
            left: '80.5%',
        }
    },
    '@media (max-width: 480px)' : {
        popoverWrapper: {
            top: '71%',
            left: '78.5%',
        }
    },
    '@media (max-width: 460px)' : {
        popoverWrapper: {
            top: '69%',
            left: '75.5%',
        }
    },
    '@media (max-width: 440px)' : {
        popoverWrapper: {
            top: '67%',
            left: '75.5%'
        }
    },
    '@media (max-width: 414px)' : {
        popoverWrapper: {
            top: '65%',
            left: '75.5%'
        }
    },
    '@media (max-width: 393px)' : {
        popoverWrapper: {
            top: '65%',
            left: '75.5%'
        }
    },
    '@media (max-width: 390px)' : {
        popoverWrapper: {
            top: '64%',
            left: '75.5%',
        }
    },
    '@media (max-width: 375px)' : {
        popoverWrapper: {
            top: '63%',
            left: '75.5%'
        }
    },
    '@media (max-width: 360px)' : {
        popoverWrapper: {
            top: '61%',
            left: '75.5%'
        }
    },
    '@media (max-width: 320px)' : {
        popoverWrapper: {
            top: '58%',
            left: '73%'
        }
    },
    '@media (max-width: 280px)' : {
        popoverWrapper: {
            top: '51%'
        }
    }
});
