import React, { FC, useEffect }  from 'react';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

interface DropdownProps {
    value: string;
    options: { label: string, value: string }[];
    handleSelect: (value: string) => void;
    error?: boolean | null;
    label?: string;
    required?: boolean;
    nightTheme?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({ value, options, handleSelect, error = false, label = 'Select option', required, nightTheme }) => {
    const {
        dropdownWrapper,
        dropdownInputWrapper,
        dropdownInputLabelActiveClass,
        dropdownInputLabelErrorClass,
        dropdownInputErrorClass,
        dropdownMenu,
        dropdownMenuItem,
        selectedItem,
        selectedItemDark,
        arrow,
        openedDropdown,
        dropdownInputWrapperDark,
        dropdownMenuDark,
        dropdownMenuItemDark,
        dropdownInputLabelDark
    } = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleUserSelect = (selectedValue: string) => {
        handleSelect(selectedValue);
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {

        const handleDocumentClick = (e: any) => {
            const element = e.target as HTMLElement;

            if ((element.parentElement?.classList.length && !element.parentElement?.classList[0].includes('dropdown')) || !element.parentElement?.classList.length) {
                setOpen(false);
            }
        };

        document.addEventListener('click', handleDocumentClick, false);

        return () => {
            document.removeEventListener('click', handleDocumentClick, false)
        }
    }, []);

    const isError = !value && required;

    const combinedInputWrapperClass = `${dropdownInputWrapper} ${open && openedDropdown} ${(isError || error) && dropdownInputErrorClass} ${nightTheme && dropdownInputWrapperDark}`;

    const combinedInputLabelClass = `${value && dropdownInputLabelActiveClass} ${(isError || error) && dropdownInputLabelErrorClass} ${nightTheme && dropdownInputLabelDark}`;

    return <>
        <div className={dropdownWrapper}>
            <div className={combinedInputWrapperClass} onClick={handleOpen}>
                <Typography variant={TypographyVariants.inputLabel} className={combinedInputLabelClass}>
                    {label}
                </Typography>
                <div className={`${selectedItem} ${nightTheme ? selectedItemDark : ''}`}>
                    {options.find(o => o.value === value)?.label || value}
                </div>
                <span className={arrow} />
            </div>

            {open &&
                <ul className={`${dropdownMenu} ${nightTheme ? dropdownMenuDark : ''}`}>
                    {options.map(option => (
                        <li key={option.value} className={`${dropdownMenuItem} ${nightTheme ? dropdownMenuItemDark : ''}`} onClick={handleUserSelect.bind(this, option.value)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            }

            {(isError && !open) && <Typography variant={TypographyVariants.validatedInputError}>{'Field is required'}</Typography> }
        </div>
    </>;
};
