import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    pageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '40px',
        position: 'absolute',
        width: '100vw',
        height: '100%',
        padding: '24px',
        background: '#F6F6F6',
    },
    pageContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '560px'
    },
    pageTitle: {
        fontSize: '20px',
        lineHeight: '28px'
    },
    loaderWrapper: {
        width: '100%',
        display: 'flex',
        gap: '40px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    modalWindow: {
        padding: '24px',
        margin: '0 32px'
    },
    '@media screen and (max-width: 375px)': {
        pageWrapper: {
            position: 'relative'
        }
    },
    '@media screen and (min-width: 768px)': {
        pageTitle: {
            margin: '32px 0 16px'
        }
    }
});
