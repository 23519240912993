import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import { useStyles } from './styles';

export interface TextAreaInputProps {
    onChange: (value: string) => void;
    value: string;
    maxCharacters: number;
    label: string;
}

export const TextAreaInput: FC<TextAreaInputProps> = ({ onChange, value, maxCharacters, label }) => {
    const { t } = useTranslation();
    const {
        textAreaInputWrapper,
        textAreaInput,
        textAreaInputActiveClass,
        textAreaInputLabel,
        textAreaInputLabelActive,
        charactersCounterStyles
    } = useStyles();

    const [activeClass, setActiveClass] = useState(false);

    const handleTextAreaInputValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setActiveClass(true);
        onChange(event.target.value);
    };

    const handleTextAreaInputBlur = () => {
        setActiveClass(false);
    };

    const charactersCounter = maxCharacters - value?.length;

    return (
        <>
            <div className={`${textAreaInputWrapper} ${activeClass && textAreaInputActiveClass}`}>
                <label
                    className={activeClass || value ? `${textAreaInputLabel} ${textAreaInputLabelActive}` : textAreaInputLabel}>
                    {label !== '' ? t(label) : t('multichoice-button-other-option-input-label')}
                </label>
                <textarea
                    className={textAreaInput}
                    value={value}
                    onFocus={handleTextAreaInputValue}
                    onChange={handleTextAreaInputValue}
                    onBlur={handleTextAreaInputBlur}
                    maxLength={maxCharacters}
                />
            </div>
            <Typography variant={TypographyVariants.h3} className={charactersCounterStyles}>
                {charactersCounter}
            </Typography>
        </>
    );
}
