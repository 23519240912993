import React from 'react';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';

import Accordion from 'components/accordion';
import { CheckPointsList } from 'components/check-points-list';

import { useStyles } from './styles';

interface MedicationAccordionProps {
    title: string;
    listedItems: any[];
}

export const MedicationAccordion = ({ title, listedItems }: MedicationAccordionProps) => {

    const {
        accordionWrapper,
        blockTitle,
        subtitleClass,
        descriptionClass,
        head,
        chevron,
        openChevron,
        headTitle,
        itemWrapperClass,
        checkPointsListWrapper,
        checkPointText
    } = useStyles();

    const TitleComponent = ({ title, isOpen }: any) => {
        return <div className={head}>
            <Typography variant={TypographyVariants.h2} className={headTitle}>
                {title}
            </Typography>
            <span className={`${chevron} ${isOpen && openChevron}`}></span>
        </div>
    };

    const DetailComponent = ({ subtitle, items, description }: any) => {
        return <div>
            {subtitle && <Typography variant={TypographyVariants.h2} className={subtitleClass}> {subtitle} </Typography>}

            {items && <CheckPointsList items={items} listItemClassName={checkPointsListWrapper} listItemTextClassName={checkPointText} />}

            {description && <Typography variant={TypographyVariants.h3} className={descriptionClass}> {description} </Typography>}
        </div>
    };

    return (
        <div className={accordionWrapper}>
            <Typography variant={TypographyVariants.h1} className={blockTitle}>
                {title}
            </Typography>

            <Accordion
                items={listedItems}
                TitleComponent={TitleComponent}
                DetailComponent={DetailComponent}
                itemWrapperClass={itemWrapperClass}
            />
        </div>
    );
};
