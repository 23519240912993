import { MultiChoiceType } from 'components/multichoice-button';

export const getMultiChoiceButtonSelectOptions = (pageValue: string[], multiChoices: MultiChoiceType[]) => {
    return pageValue.map((item: string) => {
        const currentOption = multiChoices.find((option) => option.key === item);

        return currentOption?.key === 'other' ? `${currentOption.value}: ${currentOption?.userAnswer?.trim()}` : currentOption?.value;
    }) as string[];
};

export const isNextButtonDisabled = (pageValue: string[], multiChoices: MultiChoiceType[]) => {
    const isAnswerEmpty = (answer: string) => answer.trim().length === 0;
    const isEveryAnswerValid = pageValue.every((value: string) => {
        const option = multiChoices.find((opt) => opt.key === value);
        return (option?.key === 'none' || option?.userAnswer === undefined) ? true : !isAnswerEmpty(option?.userAnswer?.toString() as string);
    });

    return !pageValue.length || !isEveryAnswerValid;
};
