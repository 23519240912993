// node modules
import { createUseStyles } from 'react-jss';

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ multiChoiceButton }: ThemeProps) => ({
    multiChoiceButton: {
        ...multiChoiceButton.multiChoiceButton,
        display: 'flex',
        alignItems: 'center',
        '&:active': {
            ...multiChoiceButton.multiChoiceButtonHovered,
        }
    },
    multiChoiceButtonSelected: {
        ...multiChoiceButton.multiChoiceButtonSelected,
    },
    multiChoiceButtonDisabled: {
        ...multiChoiceButton.multiChoiceButtonDisabled,
    },
    imageClass: {
        width: '32px',
        height: '32px',
        marginLeft: 'auto',
    },
    '@media (hover: hover)': {
        multiChoiceButton: {
            "&:hover": {
                ...multiChoiceButton.multiChoiceButtonHovered,
            },
        },
        multiChoiceButtonSelected: {
            "&:hover": {
                ...multiChoiceButton.multiChoiceButtonSelected,
            },
        }
    }
}));
