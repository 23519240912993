import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        textAlign: 'left',

        '@media (min-width: 768px)' : {
            fontSize: '24px',
            lineHeight: '28px',
            textAlign: 'center'
        }
    },
    subtitle: {
        fontWeight: 400,
        textAlign: 'left',
        margin: '16px 0 32px',

        '@media (min-width: 768px)' : {
            textAlign: 'center',
            margin: '16px 0 40px',
        }
    },
    weightFactorsIllustrationWrapper: {
        width: '100%',
        maxWidth: '560px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '12px',

        '@media (min-width: 768px)' : {
            marginBottom: '16px'
        },

        '@media (max-width: 320px)' : {
            '& img': {
                width: '100%'
            },
        }
    }
});
