import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginBottom: '16px',

        '& > h1': {
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            margin: '24px 0 0',
        }
    },
    '@media (min-width: 768px)': {
        contentWrapper: {

            '& > h1': {
                fontSize: '34px',
                lineHeight: '44px',
                margin: '32px 0 0',
            }
        },
    }
});
