import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { RoutePath } from 'routes/route-path.constant';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';
import { LocalStorageKeys } from 'constants/local-storage';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const BeforeAfterMedication = () => {
    const { t } = useTranslation();

    const { contentWrapper, image, infoText } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = [
        'webp/before-after-medication/before-after-medication-female.webp',
        'webp/before-after-medication/before-after-medication-male.webp'
    ];
    const pageImages = useBackgroundImageService(items);

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);
    const imageIndex = userGender === 'female' ? 0 : 1;

    return (
        <div className={contentWrapper}>
            <Typography variant={TypographyVariants.h1} typographyNext>
                <Trans i18nKey='before-after-medication-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey='before-after-medication-subtitle' />
            </Typography>

            <img src={pageImages[imageIndex]} className={image} alt='Before/After' />

            <NextButton typographyText={t('before-after-medication-button-next')} onClick={handleClick} />

            <Typography variant={TypographyVariants.h3} className={infoText}>
                <Trans i18nKey='before-after-medication-info' />
            </Typography>
        </div>
    );
};
