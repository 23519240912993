import React from 'react';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from '../../typography';

import errorIcon from './error_icon.svg';

import { useStyles } from './styles';

type ErrorBlockProps = {
    error: string,
}

export const ErrorBlock = ({ error }: ErrorBlockProps) => {
    const { errorWrapper, errorTypography } = useStyles();

    return (
        <div className={errorWrapper}>
            <img src={errorIcon} alt="icon"/>
            <Typography variant={TypographyVariants.h3} className={errorTypography}>
                {error}
            </Typography>
        </div>
    )
};
