import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { CustomerCard, CustomerCardType } from 'pages/medication/video-customer-reviews/customer-card';
import { VideoPlayer } from 'pages/medication/video-player';
import { reviewsConfig } from 'pages/medication/video-customer-reviews/customer-card/config';

import { useStyles } from './styles';

export const VideoCustomerReviews = () => {
    const {
        customerVideoReviewsWrapper,
        title,
        customerReviewsSwiper,
        swiperSlide
    } = useStyles();

    const [currentVideoName, setCurrentVideoName] = useState<string | null>(null);

    const items = [
        'svg/medication/video-customer-reviews/prev-button.svg', 'svg/medication/video-customer-reviews/next-button.svg'
    ];
    const pageImages = useBackgroundImageService(items);
    const isDesktop = useDesktopMediaWatcher();

    return (
        <div className={customerVideoReviewsWrapper}>
            {currentVideoName &&
                <VideoPlayer videoId={reviewsConfig.find(customerCard => customerCard.name === currentVideoName)?.videoId as string}
                             onClose={() => setCurrentVideoName(null)} />}

            <Typography className={title} variant={TypographyVariants.h1}>
                <Trans i18nKey='medication-video-customer-reviews-title' />
            </Typography>
            <Splide
                className={customerReviewsSwiper}
                hasTrack={false}
                options={{
                    perPage: 4.5,
                    perMove: 1,
                    gap: 24,
                    pagination: false,
                    type: 'loop',
                    infinite: true,
                    center: !isDesktop,
                    fixedWidth: isDesktop ? undefined : '60%',
                    focus: isDesktop ? undefined : 'center',
                    breakpoints: {
                        280: {
                            perPage: 1.5,
                        },
                        479: {
                            perPage: 2.5,
                            gap: 16,
                        },
                        768: {
                            perPage: 2,
                        },
                        850: {
                            perPage: 3,
                        },
                        1200: {
                            perPage: 3.5,
                        }
                    }
                }}
            >
                <div className='splide__arrows splide__arrows--ltr'>
                    <div className='splide__arrow splide__arrow--prev'>
                        <img src={pageImages[0]} alt='Prev Button' />
                    </div>
                    <div className='splide__arrow splide__arrow--next'>
                        <img src={pageImages[1]} alt='Next Button' />
                    </div>
                </div>
                <SplideTrack>
                    {reviewsConfig.map((customerCard: CustomerCardType) =>
                        <SplideSlide key={customerCard.name} className={swiperSlide}
                                     style={{ backgroundImage: customerCard.backgroundPhoto }}>
                            <CustomerCard
                                customerCard={customerCard}
                                setCurrentVideoName={setCurrentVideoName}
                            />
                        </SplideSlide>
                    )}
                </SplideTrack>
            </Splide>
        </div>
    );
};
