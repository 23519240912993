import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
   inputWrapper: {
      marginBottom: '12px',

      '@media (min-width: 768px)' : {
         marginBottom: '20px'
      }
   }
});
