import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { LbsInput } from 'components/mass-input/lbs-input';

import { getBmi } from 'services/body-plan-calculator.rx.service';

import { FtInchesInputs } from './ft-inches-inputs';

import { useStyles } from './styles';

type ErrorType = {
    error: boolean;
    key: string;
}

export interface BodyHeightWeightInputsProps {
    heightInput: boolean;
    idealWeightInput: boolean;
    setInputError: Dispatch<SetStateAction<ErrorType>>;
    userUnderweight?: boolean;
    setEligibleBMIValue?: (eligibleBMIValue: boolean) => void;
    setUserUnderweight?: (underweight: boolean) => void;
    pageError: boolean;
}

export const BodyHeightWeightInputs: FC<BodyHeightWeightInputsProps> = ({
                        heightInput,
                        idealWeightInput,
                        setInputError,
                        userUnderweight,
                        setEligibleBMIValue,
                        setUserUnderweight,
                        pageError
    }) => {
    const saveAnswers = useSaveAnswers();
    const { t } = useTranslation();

    const { marginTop, thanksForSharingTextWrapper, thanksForSharingText, excitementText } = useStyles();

    const { pageValue: bodyHeightFt, setPageValue: setBodyHeightFt } = useLocalStorage({
        key: MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches, setPageValue: setBodyHeightInches } = useLocalStorage({
        key: MultipleLocalStorageKeys.bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const { pageValue: bodyWeightLbs, setPageValue: setBodyWeightLbs } = useLocalStorage({
        key: MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    const { pageValue: bodyIdealWeight, setPageValue: setBodyIdealWeight } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    const minLbsValue = 59;
    const maxLbsValue = 1002;
    const minFtValue = 1;
    const maxFtValue = 9;
    const maxInchesValue = 12;

    const onChangeFt = (e: any) => {
        setBodyHeightFt(e.target.value);
        const { name } = e.target;
        const [, fieldIndex] = name.split('-');

        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (e.target.value.length === 1 && e.target.value > 1 && e.target.value < 9) {
            const nextField = document.querySelector(
                `input[name=field-${fieldIntIndex + 1}]`,
            ) as HTMLInputElement;
            nextField.focus();
        }
    };
    const onChangeInches = (e: any) => {
        setBodyHeightInches(e.target.value);
    };

    const onChangeLbs = (e: any) => {
        idealWeightInput ? setBodyIdealWeight(e.target.value) : setBodyWeightLbs(e.target.value);
    };

    const onError = (error: boolean, key: string) => {
        setInputError((prevState: ErrorType) => ({
            ...prevState,
            [key]: error,
        }));
    };

    const isBodyHeightInchesEmpty = bodyHeightInches === '';
    const adjustedBodyHeightInches = isBodyHeightInchesEmpty ? 0 : parseInt(bodyHeightInches);
    const currentWeight = parseInt(bodyWeightLbs);
    const idealWeight = parseInt(bodyIdealWeight);
    const weight = idealWeightInput ? idealWeight : currentWeight;
    const height = parseInt(bodyHeightFt) * 12 + adjustedBodyHeightInches;

    const bmi = getBmi(weight, height);

    useEffect(() => {

        const eligibleBMIValue =
            !!bodyWeightLbs &&
            bodyWeightLbs > minLbsValue &&
            bodyWeightLbs !== '' &&
            bodyWeightLbs < maxLbsValue &&
            bodyHeightFt > minFtValue &&
            bodyHeightFt !== '' &&
            bodyHeightFt < maxFtValue &&
            bodyHeightInches < maxInchesValue &&
            bmi > 20;

        const underweightValue =
            !!bodyIdealWeight &&
            bodyIdealWeight > minLbsValue &&
            bodyIdealWeight !== '' &&
            bodyIdealWeight < maxLbsValue &&
            bodyHeightFt > minFtValue &&
            bodyHeightFt !== '' &&
            bodyHeightFt < maxFtValue &&
            bodyHeightInches < maxInchesValue &&
            bmi < 18.5;

        if (setEligibleBMIValue) {
            setEligibleBMIValue(eligibleBMIValue);
        }

        if (setUserUnderweight) {
            setUserUnderweight(underweightValue);
        }

    }, [bodyWeightLbs, bodyHeightFt, bodyHeightInches, bodyIdealWeight, bmi]);

    useEffect(() => {
        saveAnswers({
            key: MultipleLocalStorageKeys.bodyHeightWeightKeys.bmi,
            itemQuestion: { question: t('medication-intake-form-user-bmi-question'), value: getBmi(currentWeight, height).toFixed(2) }
        });
    }, [currentWeight, height]);

    return (
        <>
            <>
                {heightInput && <FtInchesInputs
                    ft={bodyHeightFt}
                    inches={bodyHeightInches}
                    onChangeFt={onChangeFt}
                    onChangeInches={onChangeInches}
                    onError={onError}
                />}

                {!heightInput && !idealWeightInput && <div className={marginTop}>
                    <LbsInput value={bodyWeightLbs} onChange={onChangeLbs} onError={onError} />
                </div>}

                {!heightInput && idealWeightInput && <div className={marginTop}>
                    <LbsInput value={bodyIdealWeight || ''} lessThen={Number(bodyWeightLbs)} onChange={onChangeLbs}
                              onError={onError} />
                </div>}
            </>

            {!pageError && bodyWeightLbs && !heightInput && !idealWeightInput && (
                <div className={thanksForSharingTextWrapper}>
                    <Typography className={thanksForSharingText} variant={TypographyVariants.h3}>
                        {t('body-height-weight-thanks-for-sharing-title')}
                    </Typography>
                    <Typography className={thanksForSharingText} variant={TypographyVariants.h3}>
                        {t('body-height-weight-thanks-for-sharing-description')}
                    </Typography>
                </div>
            )}

            {!pageError && bodyIdealWeight && !userUnderweight && idealWeightInput && (
                <>
                    <Typography className={excitementText} variant={TypographyVariants.h3}>
                        {t('body-ideal-weight-goal-excitement-title')}
                    </Typography>
                    <Typography className={excitementText} variant={TypographyVariants.h3}>
                        {t('body-ideal-weight-goal-excitement-description')}
                    </Typography>
                </>
            )}
        </>
    );
};
