import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLastLocation } from 'hooks/use-last-location.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const DeclineUser = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const lastLocation = useLastLocation();

    const {
        title,
        subtitle,
        iconWrapper,
        continueButtonWrapper,
        continueButton,
        link,
        changeAnswersButton
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);
    const userCurrentBodyHeightFt = localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt);
    const userCurrentBodyHeightInch = localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.heightInches);
    const userCurrentBodyWeight = localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs);
    const userGoalWeight = localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight]);
    const unitSystem = localStorage.getItem(MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem);

    let url = `${process.env.REACT_APP_ABLE_UI_URL}/body-age?gender=${userGender}&currentbodyheight=${userCurrentBodyHeightFt}.${userCurrentBodyHeightInch}&currentbodyweight=${userCurrentBodyWeight}&goalbodyweight=${userGoalWeight}&bodyHeightMetricSystem=${unitSystem}`;

    const handleClick = () => {
        localStorage.clear();
        pageConfiguration.handleOnPageComplete();
    };


    const handleChangeAnswersClick = () => {
        pageConfiguration.event('SemaRxWeightChangeAnswersClicked');
        // @TODO Think about how we can optimize this logic for using in GrowthBook experiments
        // In case user entered not eligible answer on body-weight page, the DeclineUser component
        // is shown after body-ideal-weight page BUT redirect should be to body-weight page
        lastLocation?.pathname === RoutePath.BodyIdealWeight ? navigate(RoutePath.BodyWeight) : navigate(-1);
    };

    const items = ['svg/decline-user/no-medication.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <div className={iconWrapper}>
                <img src={pageImages[0]} alt='Without Medications Icon' />
            </div>

            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey='decline-user-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='decline-user-description' components={[<strong></strong>]} />
            </Typography>

            <div className={continueButtonWrapper}>
                <button className={continueButton}>
                    <a className={link} href={url} target='_blank' rel='noreferrer nofollow' onClick={handleClick}>
                        <Typography variant={TypographyVariants.button}>
                            {t('decline-user-next-button')}
                        </Typography>
                    </a>
                </button>
            </div>
            <NextButton className={changeAnswersButton} typographyText={t('decline-user-next-change-button')}
                        onClick={handleChangeAnswersClick} />
        </>
    );
};
