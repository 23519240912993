import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        textAlign: 'left',

        '@media (min-width: 768px)' : {
            fontSize: '24px',
            lineHeight: '28px',
            textAlign: 'center'
        }
    },
    subtitle: {
        textAlign: 'left',
        margin: '16px 0 32px',

        '@media (min-width: 768px)' : {
            textAlign: 'center',
            margin: '16px 0 40px',
        }
    },
    graphicTitle: {
        fontWeight: 600,
        textAlign: 'left',
        margin: '0 0 16px',
        alignSelf: 'flex-start',
    },
    graphicImage: {
        width: '100%',
        maxWidth: '560px',
        height: 'auto',
    },
    graphicSubTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '18px',
        width: '100%',
        margin: '8px 0 12px',

        '@media (min-width: 768px)' : {
            margin: '12px 0 16px'
        }
    },
    graphicSubTitle: {
        fontSize: '12px',
        color: '#6B6B6B',
        margin: 'unset',
    }
});
