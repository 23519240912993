import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    dotSymbol: {
        position: 'absolute',
        borderRadius: '50%',
        width: '13px',
        height: '13px',
        backgroundColor: '#36B992',
        top: '124.5%',
        left: '44%',
        border: '3px solid #FFFFFF',
    },
    popoverWrapper: {
        position: 'absolute',
        opacity: 0,
        animation: 'opacityAnimation 2s ease-out 1.2s forwards',
    },
    popover: {
        background: '#36B992',
        borderRadius: '8px',
        '&::after': {
            border: 'solid transparent',
            content: '" "',
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(255, 255, 255, 0)',
            borderWidth: '5px',
            left: '50%',
            top: '100%',
            borderTopColor: '#36B992',
            marginLeft: '-3px',
        },
        '&::before': {
            border: 'solid transparent',
            content: '" "',
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(0, 0, 0, 0)',
            left: '50%',
            top: '100%',
            marginLeft: '-11px',
        }
    },
    popoverText: {
        fontFamily: 'SF Pro Text',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '18px',
        padding: '8px 12px',
        color: 'white',

        '@media (max-width: 280px)' : {
            fontSize: '11px',
            textAlign: 'center'
        },
    }
});
