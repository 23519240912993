import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

export const PrescriptionMedications = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PrescriptionMedications];

    const { pageValue: glp1Medications, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('prescription-medications-title'), value: t(answer.value) },
        });

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const answers: ChoiceType[] = [
        {
            key: 'never-heard',
            value: t('prescription-medications-option-never-heard'),
        },
        {
            key: 'heard',
            value: t('prescription-medications-option-heard'),
        },
        {
            key: 'expert',
            value: t('prescription-medications-option-expert'),
        }
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('prescription-medications-title')}
            </Typography>

            {answers.map((v: ChoiceType) => (
                <ChoiceButton
                    key={v.key}
                    center={true}
                    selected={v.key === glp1Medications}
                    typographyText={v.value}
                    onClick={() => handleClick(v)}
                />
            ))}
        </>
    );
};
