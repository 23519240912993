import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    graphWrapper: {
        background: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 4px',
        margin: '16px 0px',
    },
    weightWrapper: {
        background: '#DDF3ED',
        borderRadius: '4px',
        padding: '0px 6px',
        display: 'inline-block',
        marginLeft: '8px',
    },
    preTitle: {
        fontSize: '12px',
        lineHeight: '16px',
        margin: '0px 0px 10px'
    },
    title: {
        fontSize: '18px',
        lineHeight: '26px',
        margin: '0px 0px 16px'
    },
    info: {
        padding: '0px 16px',
        marginTop: '24px',
    },
    '@media (min-width: 768px)': {
        graphWrapper: {
            margin: '24px 0px',
            padding: '24px',
        },
        preTitle: {
            fontSize: '14px',
            lineHeight: '18px',
            margin: '0px 0px 8px'
        },
        title: {
            fontSize: '22px',
            lineHeight: '28px',
            margin: '0px 0px 32px'
        },
        info: {
            fontSize: '18px',
            lineHeight: '26px',
            padding: '0px'
        }
    }
});
