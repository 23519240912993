import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { BodyHeightWeightInputs } from 'components/body-height-weight-inputs';
import { UserEligibilityKeys } from 'constants/user-eligibility';

export const BodyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;

    const [pageError, setPageError] = useState(false);
    const { setPageValue: setEligibleBMIValue } = useLocalStorage({
        key: UserEligibilityKeys.isBMIValueEligible,
        defaultValue: true,
    });

    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        const data = {
            unit: bodyHeightWeightUnitSystem,
            weight: bodyWeightLbs
        };

        saveAnswers({
            key: bodyHeightWeightKeys.weightLbs,
            itemQuestion: { question: t('body-weight-title'), value: `Weight: ${data.weight} lbs` },
        });
        pageConfiguration.handleOnPageComplete(data);
    };

    const { pageValue: bodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: UNIT_SYSTEM.imperial
    });

    const { pageValue: bodyWeightLbs } = useLocalStorage({
        key: bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    useEffect(() => {

        setPageError(() => {
            // @ts-ignore
            return Object.keys(errors).some((key) => errors[key]);
        });

    }, [JSON.stringify(errors)]);

    useEffect(() => {
        const btnState = pageError || !bodyWeightLbs;

        setDisabledButtonState(btnState);

    }, [
        bodyHeightWeightUnitSystem,
        bodyWeightLbs,
        pageError
    ]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>{t('body-weight-title')}</Typography>

            <Typography variant={TypographyVariants.h2}>{t('body-weight-subtitle')}</Typography>

            <BodyHeightWeightInputs heightInput={false} idealWeightInput={false} setEligibleBMIValue={setEligibleBMIValue} setInputError={setError} pageError={pageError} />

            <NextButton onClick={handleClick} disabled={disabledButtonState} />
        </>
    );
};
