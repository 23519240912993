import * as MedusaTypes from '@medusajs/medusa';

interface CustomMedusaMetadata {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}
export interface BundleProduct {
    product_id: string;
    variant_id: string;
    title: string;
    description?: string | null;
    externalId?: string;
    key: string;
    quantity?: number;
    productType?: string;
}

export enum DiscountType {
    None = 'none',
    Flat = 'flat',
    Percentage = 'percentage'
}

export interface BundlePriceAndPeriod {
    price: number;
    periodDays: number;
    trialPrice: number;
    trialPeriodDays: number;
    discountAmount: number;
    discountType: DiscountType;
    effectivePrice: number;
    effectiveTrialPrice: number;
    chargePeriodUnit: string;
    currency: string;
}

export interface Product extends Omit<BundleProduct, 'product_id' | 'variant_id'>, BundlePriceAndPeriod {}

export interface MedusaProductVariant extends BundleProduct, MedusaTypes.ProductVariant {
    metadata: CustomMedusaMetadata;
}

export interface MedusaProduct extends MedusaTypes.Product {
    metadata: CustomMedusaMetadata;
    variants: MedusaProductVariant[];
    amount: number;
}

export interface Bundle {
    id: string;
    planId: string,
    title: string;
    source: string;
    instructions: string;
    description: string;
    payUpfront: boolean;
    products: BundleProduct[];
    priceAndPeriod: BundlePriceAndPeriod;
    planCode: string;
}
export interface BundlesResponse {
    bundles: Bundle[];
    pagination: {
        total: number;
        totalPages: number;
        currentPage: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
}
