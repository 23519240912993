export const USA_COUNTRY_CODE = '+1';

export const AGES = [
    { min: 0, max: 19, age: 'underTwenty' },
    { min: 20, max: 29, age: 'twentys' },
    { min: 30, max: 39, age: 'thirtys' },
    { min: 40, max: 49, age: 'fortys' },
    { min: 50, max: 59, age: 'fiftys' },
    { min: 60, max: 100, age: 'sixtyPlus' },
];
