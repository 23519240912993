import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    radioButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        alignSelf: 'stretch',
        padding: '16px',
        borderRadius: '8px',
        border: '2px solid #E0E3EB',
        background: '#FFFFFF',
        cursor: 'pointer',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',

        '& > div': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
        },

        '& > div > h2, h3': {
            margin: 'unset',
            textAlign: 'left'
        },

        '& > div > h2': {
            fontWeight: 600,
            lineHeight: '22px',
        },

        '& > div > h3': {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    radioSelector: {
        position: 'relative',

        '& > input': {
            cursor: 'pointer',
            appearance: 'none',
            width: '24px',
            height: '24px',
            border: '2px solid #E0E3EB',
            borderRadius: '50%',
            outline: 'none',
            position: 'relative',
            margin: '0',
        },

        '& > input:checked': {
            borderColor: '#1375D6',
        },

        '& > span': {
            position: 'absolute',
            top: '46%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: '#1375D6',
            display: 'none',
        },

        '& > input:checked + span': {
            display: 'block',
        },
    },
    priceBlock: {
        display: 'flex',
        padding: '6px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        background: '#E0E3EB',

        '& > h3': {
            fontSize: '16px',
            fontWeight: 600,
        }
    },
    selected: {
        borderColor: '#1375D6',
    },
    '@media (min-width: 768px)': {
        contentWrapper: {

            '& > h1': {
                fontSize: '34px',
                lineHeight: '44px',
                margin: '32px 0 0',
            }
        },
        radioButton: {

            '& > div > h2': {
                lineHeight: '24px',
            },

            '& > div > h3': {
                fontSize: '18px',
                lineHeight: '26px',
            }
        },
    }
});
