import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Primer, PaymentFlow } from '@primer-io/checkout-web';

import { PrimerProvider } from 'api/primer.api';

import { RoutePath } from 'routes/route-path.constant';

import { isNumber } from 'helpers';
import { getSignUpData } from 'helpers/sign-up-data';
import { getPaymentGeneralErrorKey } from 'helpers/payment';

import { LocalStorageKeys } from 'constants/local-storage';
import { BanPrepaidCardsKeys } from 'constants/payment';

import { PrimerLoader } from 'pages/checkout/primer-loader';

import { useStyles } from './styles';

type PrimerIOPaywallType = {
    email: string;
    amount: number;
    currencyCode: string;
    onError: any;
    onBeforePaymentCreate: any;
    onPaymentCreationStart: any;
    onCheckoutComplete: any;
    onCheckoutFail: any;
    translation: {
        generalError?: string;
        creditCardForm: {
            input: {
                namePlaceholder: string;
                cardNumberPlaceholder: string;
                cardExpiryPlaceholder: string;
                cardCvvPlaceholder: string;
            };
            submitButtonText: string;
        };
    };
    setShowUserExistsMessagePrimer: React.Dispatch<React.SetStateAction<boolean>>;
};

let UniversalCheckout: any = null;

export const PrimerIOPaywall = ({
    email,
    amount,
    currencyCode,
    onError,
    onBeforePaymentCreate,
    onPaymentCreationStart,
    onCheckoutComplete,
    onCheckoutFail,
    translation,
    setShowUserExistsMessagePrimer,
}: PrimerIOPaywallType) => {
    const { submitButtonWrapper, continueButton, buttonVisible } = useStyles();
    const { t } = useTranslation();

    const [clientToken, setClientToken] = useState('');
    const [userId, setUserId] = useState('');
    const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);

    useEffect(() => {
        if (email && isNumber(amount) && currencyCode) {
            const plan = JSON.parse(
                localStorage.getItem(LocalStorageKeys[RoutePath.Medication]) as string
            );

            const data = getSignUpData({
                planCode: plan.key,
                amount: plan.effectivePrice,
                subscriptionAmount: plan.price,

                trialPeriodDays: plan.trialPeriodDays,
                chargePeriod: plan.periodDays,
                chargePeriodUnit: plan.chargePeriodUnit,
                currencyCode: plan.currency.toUpperCase(),

                // Fields for combined subscription (app & medical)
                combined: 'true',
                medusaBundleId: plan.bundleId,
                medusaPlanId: plan.planId,
                type: 'Primer',
            });

            PrimerProvider.getClientToken(data).then((data) => {
                setClientToken(data.clientToken);
                setUserId(data.userId);

                const banPrepaidCardsEnabled = localStorage.getItem(BanPrepaidCardsKeys.banPrepaidCardsEnabled);
                const banPrepaidCards = localStorage.getItem(BanPrepaidCardsKeys.banPrepaidCards);

                if (banPrepaidCardsEnabled === 'true') {
                    window.gtag('event', 'experiment_viewed', {
                        variation_id: banPrepaidCards === 'true' ? '1' : '0',
                        experiment_id: 'bil_ban_prepaid_cards',
                    });
                }
            }).catch(error => {
                    console.log('getClientToken - error', error);
                    setShowUserExistsMessagePrimer(true);
                }
            );
        }
    }, [email, amount, currencyCode]);

    const handleMySubmitButtonClick = () => {
        if (UniversalCheckout) {
            UniversalCheckout.then((data: any) => data.submit());
        }
    };

    useEffect(() => {
            if (clientToken) {
                UniversalCheckout = Primer.showUniversalCheckout(clientToken, {
                    container: '#primerio-container',
                    vault: {
                        visible: false,
                    },
                    successScreen: false,
                    form: {
                        inputLabelsVisible: false,
                    },
                    submitButton: {
                        useBuiltInButton: false, // Hide the built-in submit button
                        onVisible: (isVisible: boolean) => {
                            setIsSubmitButtonVisible(isVisible)
                        },
                    },
                    style: {
                        formSpacings: {
                            betweenLabelAndInput: '0px',
                            betweenInputs: '12px',
                        },
                        input: {
                            base: {
                                fontFamily: 'SF Pro Text, system-ui, sans-serif',
                                height: '56px',
                                borderRadius: '0.5rem',
                                borderStyle: 'none none solid none',
                                borderColor: '#FFFFFF',
                                borderWidth: '2px',
                                focus: {
                                    background: '#F6F6F6',
                                    borderColor: '#1375D6',
                                    borderWidth: '2px',
                                },
                                placeholder: {
                                    color: '#868C99',
                                },
                            },
                            error: {
                                background: '#F6F6F6',
                                borderStyle: 'none none solid none',
                                borderWidth: '2px',
                                focus: {
                                    background: '#F6F6F6'
                                },
                            }
                        },
                        inputErrorText: {
                            fontFamily: 'SF Pro Text, system-ui, sans-serif',
                            fontSize: '12px',
                            color: '#FF4D2B',
                            fontWeight: '400',
                            lineHeight: '16px',
                        },
                        submitButton: {
                            base: {
                                color: '#ffffff',
                                background: '#1375D6',
                                borderRadius: '8px',
                                fontFamily: 'SF Pro Text, system-ui, sans-serif',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                fontSize: '18px',
                            },
                            loading: {
                                color: '#ffffff',
                                background: '#1375D6',
                            },
                            disabled: {
                                color: '#ffffff',
                                background: '#1375D6',
                            }
                        },
                        focusCheckoutOnInit: false
                    },
                    paypal: {
                        paymentFlow: PaymentFlow.PREFER_VAULT
                    },
                    errorMessage: {
                        // Disable the appearance of the
                        //default error message
                        // Default to false
                        disabled: true,

                        onErrorMessageShow(message: string) {
                            onError(message);
                            onPaymentCreationStart(false);
                        },

                        onErrorMessageHide() {
                            onError('');
                        },
                    },
                    card: {
                        cardholderName: {
                            placeholder: translation.creditCardForm.input.namePlaceholder,
                        },
                        cvv: {
                            placeholder: translation.creditCardForm.input.cardCvvPlaceholder,
                        },
                        cardNumber: {
                            placeholder: translation.creditCardForm.input.cardNumberPlaceholder,
                        }
                    },
                    applePay: {
                        captureBillingAddress: true,
                    },
                    googlePay: {
                        captureBillingAddress: true,
                    },
                    async onBeforePaymentCreate({ paymentMethodType }, handler) {
                        onBeforePaymentCreate(paymentMethodType);
                        handler.continuePaymentCreation();
                    },
                    async onPaymentCreationStart() {
                        onPaymentCreationStart(true);
                    },
                    async onCheckoutComplete({ payment }) {
                        onCheckoutComplete({ ...payment, userId });
                    },
                    async onCheckoutFail(error, { payment }, handler) {
                        onCheckoutFail(error, payment);
                        handler?.showErrorMessage(t(getPaymentGeneralErrorKey()));
                        await PrimerProvider.throwErrorToWebhook('[PRIMER] [onCheckoutFail]', error);
                    }
                });
            }

            return () => {
                if (UniversalCheckout) {
                    UniversalCheckout.then((data: any) => data.teardown());
                }
            }
        },
        [clientToken]);

    return (
        <>
            <div id='primerio-container'></div>

            <div className={submitButtonWrapper}>
                <button type='submit' className={`${continueButton} ${isSubmitButtonVisible && buttonVisible}`} onClick={handleMySubmitButtonClick}>
                    {translation.creditCardForm.submitButtonText}
                </button>

                {!clientToken && <div style={{ height: '200px' }}><PrimerLoader loaderPosition={'relative'} /></div>}
            </div>
        </>
    );
};
