import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DiscountBlockWithTimer } from '@ruby-labs/ui-core-kit';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { TIMER_INITIAL_MIN, TIMER_INITIAL_SEC } from 'constants/timer';
import { Product } from 'types/medusajs';

import { NextButton } from 'components/next-button';
import { PaymentInfo } from 'components/payment-info';

import { MedicationProgramSelector } from '../medication-program-selector';
import { MedicationProgramOptions } from '../medication-program-options';

import { useStyles } from './styles';

interface MedicationProgramSummaryProps {
    onClick: () => void;
    plan: Product;
    setPlan: (value: Product) => void;
    isDiscountApplicable: boolean;
}

export const MedicationProgramSummary: FC<MedicationProgramSummaryProps> = ({ onClick, plan, setPlan, isDiscountApplicable }) => {
    const { t } = useTranslation();

    const {
        timerWrapper,
        nextButtonStyles,
    } = useStyles();

    const { pageValue: minutes, setPageValue: setMinutes } = useLocalStorage({
        key: 'medication-timer-minutes',
        defaultValue: TIMER_INITIAL_MIN,
    });

    const { pageValue: seconds, setPageValue: setSeconds } = useLocalStorage({
        key: 'medication-timer-seconds',
        defaultValue: TIMER_INITIAL_SEC,
    });

    const savingPercentage = plan?.discountAmount;
    const subscriptionPrice = plan?.price / 100;

    return (
        <>
            {isDiscountApplicable && <div className={timerWrapper}>
                <DiscountBlockWithTimer
                    title={t('medication-timer-discount-title')}
                    discountValue={t('medication-timer-discount-value', { savingPercentage })}
                    discountCoupon={t('medication-timer-discount-coupon')}
                    discountTimerTitle={t('medication-discount-timer-title')}
                    timerMinutes={minutes}
                    timerSeconds={seconds}
                    setMinutes={setMinutes}
                    setSeconds={setSeconds}
                    endOfTimerHandler={() => {
                    }}
                    minutesTitle={t('medication-discount-timer-minutes')}
                    secondsTitle={t('medication-discount-timer-seconds')}
                />
            </div>}

            <MedicationProgramSelector plan={plan} setPlan={setPlan} />

            <NextButton
                className={nextButtonStyles}
                onClick={onClick}
                typographyText={t('medication-program-get-plan-button-next')}/>

            {isDiscountApplicable && <PaymentInfo subscriptionPrice={subscriptionPrice} />}

            <MedicationProgramOptions/>
        </>
    );
};
