import { BundlesResponse } from 'types/medusajs';
import { GtagKeys } from 'constants/analytics';

class MedusaApiClass {
    public baseUrl = `${process.env.REACT_APP_MEDUSA_URL}`;

    getBundles(): Promise<BundlesResponse> {
        const gtagClientId = localStorage.getItem(GtagKeys.gtagClientId);

        return fetch(`${this.baseUrl}/store/bundles?includeProductData=true&source=pwa-sema&gtagClientId=${gtagClientId}`, {
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => response.json() as Promise<BundlesResponse>)
            .catch((e) => {
                throw e;
            });
    }
}

export const MedusaApi = new MedusaApiClass();
