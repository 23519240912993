import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const FamilyWeight = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.FamilyWeight];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'mother',
            value: t('family-weight-option-mother')
        },
        {
            key: 'father',
            value: t('family-weight-option-father')
        },
        {
            key: 'sibling',
            value: t('family-weight-option-sibling')
        },
        {
            key: 'grandparent',
            value: t('family-weight-option-grandparent')
        },
        {
            key: 'child',
            value: t('family-weight-option-child')
        },
        {
            key: 'uncle-aunt',
            value: t('family-weight-option-uncle-aunt')
        },
        {
            key: 'cousin',
            value: t('family-weight-option-cousin')
        },
        {
            key: 'none',
            value: t('family-weight-option-none'),
        },
    ];

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('family-weight-title'), value: selectOption },
        });
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('family-weight-title')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
