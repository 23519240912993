import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import useMedusaProducts from 'hooks/use-medusa-products';

import { LocalStorageKeys } from 'constants/local-storage';
import { DiscountType } from 'types/medusajs';

import { TipBanner } from '../pre-paywall/tip-banner';
import { Program } from './program';
import { Rating } from './rating';
import { VideoCustomerReviews } from './video-customer-reviews';
import { MedicationDescription } from './medication-description';
import { ReviewsIO } from './reviews-io';
import { Expectations } from './expectations';
import { FAQ } from './faq';
import { MedicationProgramSelector } from './medication-program-selector';
import { MedicationProgramOptions } from './medication-program-options';
import { MedicationProgramSummary } from './medication-program-summary';

import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const Medication = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { products } = useMedusaProducts();
    const { t } = useTranslation();

    const { pageValue: plan, setPageValue: setPlan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Medication],
        defaultValue: null,
    });

    useEffect(() => {
        if (products.length > 0 && plan === null) {
            setPlan(products[0]);
        }
    }, [products]);

    const {
        tipPageWrapper,
        childrenWrapper,
        faqWrapper,
        medicationProgramSelectorWrapper,
        medicationSummaryWrapper,
        getPlanButton,
    } = useStyles();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const isDiscountApplicable = plan?.discountType !== DiscountType.None;

    return (
        <>
            <TipBanner />

            <div className={tipPageWrapper}>
                <div className={childrenWrapper}>
                    <Program
                        onClick={handleClick}
                        plan={plan}
                        setPlan={setPlan}
                    />
                    <Rating />
                </div>
            </div>

            <div className={childrenWrapper}>
                <MedicationDescription />

                {!isDiscountApplicable && <div className={medicationProgramSelectorWrapper}>
                    <MedicationProgramSelector plan={plan} setPlan={setPlan} />

                    <NextButton
                        className={getPlanButton}
                        onClick={handleClick}
                        typographyText={t('medication-program-get-plan-button-next')} />

                    <MedicationProgramOptions />
                </div>}
            </div>

            <div>
                <VideoCustomerReviews />

                <ReviewsIO />

                <div className={childrenWrapper}>
                    <Expectations />

                    <div className={faqWrapper}>
                        <FAQ />
                    </div>

                    {isDiscountApplicable && <div className={`${medicationProgramSelectorWrapper} ${isDiscountApplicable ? medicationSummaryWrapper : ''}`}>
                        <MedicationProgramSummary
                            onClick={handleClick}
                            plan={plan}
                            setPlan={setPlan}
                            isDiscountApplicable={isDiscountApplicable} />
                    </div>}
                </div>
            </div>
        </>
    );
};
