import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    medicationOptions: {
        margin: '16px 0 0',

        '& $programItem': {
            gap: '8px',
            alignItems: 'center',
            textAlign: 'left',
            marginBottom: '12px',

            '& img': {
                width: '16px'
            },

            '& h3': {
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 600,
                margin: 'unset'
            }
        },

        '& $programItem:last-child': {
            marginBottom: 0
        }
    },
    programItem: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px',

        '& h3': {
            textAlign: 'inherit',
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
    '@media (min-width: 768px)': {
        medicationOptions: {
            margin: '32px 0',

            '& $programItem': {
                gap: '12px',
                alignItems: 'center',
                textAlign: 'left',

                '& img': {
                    width: '28px'
                },

                '& h3': {
                    fontSize: '20px',
                    lineHeight: '24px',
                    fontWeight: 600,
                    margin: 'unset'
                },

                '&:last-child': {
                    marginBottom: 0,
                },
            }
        },
        smallerMargin: {
            margin: '16px 0 0'
        },
        programItem: {

            '& h3': {
                fontSize: '20px',
                lineHeight: '26px'
            }
        },
    }
});
