import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    accountFormWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '560px'
    },
    title: {
        margin: 'unset',
        lineHeight: '24px',
        textAlign: 'left',
    },
    multipleFieldsWrapper: {
        display: 'flex',
        width: '100%',
        gap: '16px',
        maxWidth: '560px',
        margin: '0 auto',
        '& div': {
            width: '100%',
        }
    },
    continueToCheckoutButton: {
        ...nextButton.nextButton,
        margin: '16px 0 20px',
        background: '#36B992',

        '&:active': {
            ...nextButton.nextButtonFocus,
            background: '#2B9475',
        },

        '&:disabled': {
            ...nextButton.nextButtonDisabled,
            background: '#31A783',
        }
    },
    smallerMargin: {},
    '@media (min-width: 768px)' : {
        title: {
            margin: '0 0 8px'
        },
        smallerMargin: {
            margin: '24px 0 16px'
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            }
        }
    }
}));
