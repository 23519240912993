import React from 'react';

import { useStyles } from './styles';

export const GoalChartPopover = ({ weight, unit, popoverWrapperClass }: { weight: number, unit: string, popoverWrapperClass: string }) => {
    const { dotSymbol, popoverWrapper, popover, popoverText } = useStyles();

    return (
        <>
            <div className={`${popoverWrapper} ${popoverWrapperClass}`}>
                <div className={popover}>
                    <p className={popoverText}>{weight} {unit}</p>
                </div>
                <div>
                    <span className={dotSymbol}></span>
                </div>
            </div>
        </>
    )
};
