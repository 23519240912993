import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';

import { DiscountType, Product } from 'types/medusajs';

import { MedicationProgramSummary } from '../medication-program-summary';

import { useStyles } from './styles';

interface ProgramProps {
    onClick: () => void;
    plan: Product;
    setPlan: (value: Product) => void;
}

export const Program: FC<ProgramProps> = ({ onClick, plan, setPlan }) => {
    const { t } = useTranslation();

    const {
        programWrapper,
        programTitle,
        medicationWrapper,
        programItemBlock,
        programItem,
        circle,
        sustainabilityInfoWrapper,
        medicationProgramSummaryWrapper
    } = useStyles();

    const items = ['webp/medication/medications-pack.webp', 'svg/medication/calendar.svg', 'svg/medication/refund.svg', 'svg/medication/medication-bottle.svg', 'svg/medication/delivery.svg'];
    const pageImages = useBackgroundImageService(items);

    const isDiscountApplicable = plan?.discountType !== DiscountType.None;

    return (
        <div className={programWrapper}>
            <Typography variant={TypographyVariants.h1} className={programTitle}>
                {t('medication-program-title').split(':')[0]}
            </Typography>

            <div className={medicationWrapper}>
                <div>
                    <img src={pageImages[0]} alt='Medications Pack' />

                    <div className={programItemBlock}>
                        <div className={programItem}>
                            <div className={circle}>1</div>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-program-option-1')}
                            </Typography>
                        </div>
                        <div className={programItem}>
                            <div className={circle}>2</div>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-program-option-2')}
                            </Typography>
                        </div>
                        <div className={programItem}>
                            <div className={circle}>3</div>
                            <Typography variant={TypographyVariants.h3}>
                                {t('medication-program-option-3')}
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className={sustainabilityInfoWrapper}>
                    <Typography variant={TypographyVariants.h3}>
                        {t('medication-program-sustainability-message-title')}
                    </Typography>
                    <Typography variant={TypographyVariants.h3}>
                        {t('medication-program-sustainability-message-description')}
                    </Typography>
                </div>
            </div>

            <div className={isDiscountApplicable ? medicationProgramSummaryWrapper : ''}>
                <MedicationProgramSummary
                    onClick={onClick}
                    plan={plan}
                    setPlan={setPlan}
                    isDiscountApplicable={isDiscountApplicable} />
            </div>
        </div>
    );
};
