import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { BaseContext } from 'context/base.context';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { useGetAnswers } from 'hooks/use-save-answers.hook';
import { useBodyPlanData } from 'hooks/use-body-plan-data.rx.hook';
import { useImportantDateCalculation } from 'hooks/use-important-date-calc.hook';

import { TypographyVariants } from 'constants/typography-variants';
import { ImportantEventDateKeys } from 'constants/important-event-date';

import { FloatedButton } from 'components/floated-button';
import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';

import { TipBanner } from './tip-banner';

import { CurrentWeightBlock } from './current-weight-block';
import { GraphBlock } from './graph-block';
// import { GuaranteeBlock } from './guarantee-block';
import { MedicalDirectorCard } from '../medication/medication-description/medical-director-card';

import { useStyles } from './styles';

export const PrePaywall = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const { tipPageWrapper, headerWrapper, childrenWrapper, button } = useStyles();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const userGoals = useGetAnswers(LocalStorageKeys[RoutePath.LoseWeightReason]);

    const importantEventDate = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEventDate]) as string;
    const importantEventValue = localStorage.getItem(LocalStorageKeys[RoutePath.ImportantEvent]);
    const importantEvent = importantEventValue === 'other' ? t('important-event-date-other-option-event-name') : importantEventValue;
    const importantEventDateSkippedValue = localStorage.getItem(ImportantEventDateKeys.importantEventDateSkipped);
    const showImportantEventSubtitle = importantEventValue !== 'none' && importantEventDateSkippedValue === 'false';

    const {
        weightsPlan,
        weightsPlanForDisplay,
        monthsPlan,
        monthsPlanForDisplay,
        predictedGoalWeight,
        weightLbs,
        unit,
        finalDate,
    } = useBodyPlanData();

    const {
        weightAtEventDate,
        isFutureDate,
        isToday,
        isWithinWeek,
    } = useImportantDateCalculation(importantEventDate, monthsPlan, weightsPlan, monthsPlanForDisplay);

    const isEventInFuture = isFutureDate && (dayjs(new Date(importantEventDate))).isAfter(finalDate);
    const weightDifference = weightLbs - weightAtEventDate;
    const weightDifferencePlaceholder = 1;

    const getImportantEventTitleKey = () => {
        let key;
        if (isEventInFuture) {
            key = 'pre-paywall-graph-block-important-event-next-year-title';
        } else if (isToday) {
            key = 'pre-paywall-graph-block-important-event-today-title';
        } else {
            key = 'pre-paywall-graph-block-important-event-title';
        }

        return key;
    };

    return (
        <div className={tipPageWrapper}>

            <TipBanner/>

            <div className={headerWrapper}>
                <Typography variant={TypographyVariants.h1} typographyNext>
                    {t('pre-paywall-your-assessment')}
                </Typography>
                <MedicalDirectorCard />
            </div>

            <div className={childrenWrapper}>
                <CurrentWeightBlock currentWeight={weightsPlanForDisplay[0]} unit={unit} userGoals={userGoals}/>

                <GraphBlock weightGoal={predictedGoalWeight} unit={unit} weightPlan={weightsPlanForDisplay}
                            monthsPlan={monthsPlanForDisplay}
                            importantEventTitle={showImportantEventSubtitle ?
                                t(getImportantEventTitleKey(), {
                                    importantEvent,
                                    weightDifference: (weightDifference === 0 || isWithinWeek)
                                        ?
                                        weightDifferencePlaceholder
                                        :
                                        weightDifference,
                                }) : ''}
                />
            </div>

            {/* TODO: Uncomment the GuaranteeBlock after inspection */}
            {/*<GuaranteeBlock />*/}

            <FloatedButton floated={true} withGradient className={button}>
                <NextButton onClick={handleClick} typographyText={t('pre-paywall-next-button')} />
            </FloatedButton>
        </div>
    );
};
