import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';
import { CheckPointsList } from 'components/check-points-list';

import { useStyles } from './styles';

export const CurrentWeightBlock: FC<{ currentWeight: number, unit: string, userGoals: string[] }> = ({ currentWeight, unit, userGoals }) => {
    const { t } = useTranslation();

    const { currentWeightWrapper, title, weightWrapper, subTitle, goalBlock, goalItem } = useStyles();

    return (
        <div className={currentWeightWrapper}>
            <Typography variant={TypographyVariants.h1} className={title}>
                {t('pre-paywall-current-weight-title')}
                <div className={weightWrapper}>
                    {currentWeight} {unit}
                </div>
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subTitle}>
                {t('pre-paywall-current-weight-goals')}
            </Typography>

            <CheckPointsList items={userGoals} listItemClassName={goalBlock} listItemTextClassName={goalItem} />
        </div>
    );
};
