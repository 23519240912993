import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: "flex",
        marginBottom: '12px',
        width: '100%',
        justifyContent: 'space-between',

        '@media (min-width: 768px)' : {
            marginBottom: '16px'
        }
    },
    inputWrapperClass: {
        width: '48%'
    }
});
