
enum Granularity {
    GRANULARITY_UNSPECIFIED = 'GRANULARITY_UNSPECIFIED',
    SUB_PREMISE = 'SUB_PREMISE',
    PREMISE = 'PREMISE',
    PREMISE_PROXIMITY = 'PREMISE_PROXIMITY',
    BLOCK = 'BLOCK',
    ROUTE = 'ROUTE',
    OTHER = 'OTHER'
}

export enum ConfirmationLevel {
    CONFIRMATION_LEVEL_UNSPECIFIED = 'CONFIRMATION_LEVEL_UNSPECIFIED',
    CONFIRMED = 'CONFIRMED',
    UNCONFIRMED_BUT_PLAUSIBLE = 'UNCONFIRMED_BUT_PLAUSIBLE',
    UNCONFIRMED_AND_SUSPICIOUS = 'UNCONFIRMED_AND_SUSPICIOUS'
}

export interface AddressComponent {
    componentName: {
        text: string,
        languageCode: string
    },
    componentType: string,
    confirmationLevel: ConfirmationLevel,
    inferred?: boolean,
    spellCorrected?: boolean,
    replaced?: boolean,
    unexpected?: boolean
}

export interface Address {
    formattedAddress: string,
    postalAddress: {
        revision: number,
        regionCode: string,
        languageCode: string,
        postalCode: string,
        sortingCode: string,
        administrativeArea: string,
        locality: string,
        sublocality: string,
        addressLines: string[],
        recipients: string[],
        organization: string
    },
    addressComponents: AddressComponent[],
    missingComponentTypes: string[],
    unconfirmedComponentTypes?: string[],
    unresolvedTokens: string[]
}

export interface ValidationResult {
    verdict: {
        inputGranularity: Granularity,
        validationGranularity: Granularity,
        geocodeGranularity: Granularity,
        addressComplete?: boolean,
        hasUnconfirmedComponents?: boolean,
        hasInferredComponents?: boolean,
        hasReplacedComponents?: boolean
    },
    address: Address,
    geocode: {
        location: any,
        plusCode: any,
        bounds: any,
        featureSizeMeters: number,
        placeId: string,
        placeTypes: [
            string
            ]
    },
    metadata: {
        business: boolean,
        poBox: boolean,
        residential: boolean
    },
    uspsData: any,
    englishLatinAddress: Address
}

class GoogleAddressValidationApi {

    private previousResponseId = '';

    validate(address: any): Promise<ValidationResult> {
        return fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_API_KEY_TOKEN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ address, previousResponseId: this.previousResponseId })
        })
            .then(response => response.json())
            .then((response) => {
                if (response.responseId) {
                    this.previousResponseId = response.responseId;
                    return response.result;
                }
                return Promise.reject(response.error);
            })
            .catch((e) => {
                alert(`Address validation: ${e.code} ${e.status}, Please contact support`);
                return Promise.reject(e);
            });
    }
}

export const GoogleAddressValidation = new GoogleAddressValidationApi();
