import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    errorWrapper: {
        border: '1px solid #FF4D2B',
        background: '#FBEBEB',
        display: 'flex',
        padding: '12px 16px',
        width: '100%',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        borderRadius: '10px',
        margin: '16px 0',
    },
    errorTypography: {
        textAlign: 'left',
        letterSpacing: '0.0008em',
        color: '#121620',
        marginLeft: '10px',
        width: '100%'
    }
});
