import { createUseStyles } from 'react-jss';

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ dropdown, input, typography }: ThemeProps) => ({
    dropdownWrapper: {
        position: 'relative',
        maxWidth: '560px',
        margin: '0 auto',
    },
    dropdownInputWrapper: input.inputWrapper,
    dropdownInputWrapperDark: input.inputWrapperDark,
    dropdownInputLabelActiveClass: typography.inputLabelActive,
    dropdownInputLabelErrorClass: typography.inputLabelError,
    dropdownInputErrorClass: input.inputErrorClass,
    dropdownInputLabelDark: typography.inputLabelDark,
    dropdownMenu: dropdown.dropdownMenu,
    dropdownMenuDark: {
        background: '#010101',
        border: '1px solid #373739'
    },
    dropdownMenuItem: dropdown.dropdownMenuItem,
    dropdownMenuItemDark: {
        background: '#010101',
        color: '#FFFFFF'
    },
    selectedItem: dropdown.selectedItem,
    selectedItemDark: {
        color: '#FFFFFF'
    },
    arrow: dropdown.arrow,
    openedDropdown: dropdown.openedDropdown,
}));
