import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ choiceButton }: ThemeProps) => ({
    choiceButtonWrapper: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '560px'
    },
    choiceButton: {
        ...choiceButton.choiceButton,
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'unset',
        maxWidth: 'unset',
        maxHeight: 'unset',
        padding: '16px',
        flexBasis: '95%',

        '&:active': {
            ...choiceButton.choiceButtonHovered,
        },
    },
    choiceButtonSelected: {
        ...choiceButton.choiceButtonSelected,
    },
    '@media (hover: hover)': {
        choiceButton: {
            "&:hover": {
                ...choiceButton.choiceButtonHovered
            }
        }
    }
}));
