import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';
import { FloatedButton } from 'components/floated-button';
import { NextButton } from 'components/next-button';

export const ImportantEvent = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: importantEvent, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.ImportantEvent],
        defaultValue: '',
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.ImportantEvent]}-other-userAnswer`,
        defaultValue: '',
    });

    const events: ChoiceType[] = [
        { key: 'vacation', value: t('important-event-option-vacation') },
        { key: 'wedding', value: t('important-event-option-wedding') },
        { key: 'holiday', value: t('important-event-option-holiday') },
        { key: 'summer', value: t('important-event-option-summer') },
        { key: 'reunion', value: t('important-event-option-reunion') },
        { key: 'birthday', value: t('important-event-option-birthday') },
        { key: 'other', value: t('important-event-option-other') },
        { key: 'none', value: t('important-event-option-none') },
    ];

    // Set otherOptionAnswer initial value to avoid using null
    const initialOtherOptionAnswerValue = events.find(event => event.key === 'other') as ChoiceType;
    const [otherOptionAnswer, setOtherOptionAnswer] = useState<ChoiceType>(initialOtherOptionAnswerValue);

    const handleChoiceButtonClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        if (answer.key !== 'other') {
            handleClick(answer);
        } else {
            setOtherOptionAnswer(answer);
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
        }
    };

    const handleClick = (answer: ChoiceType) => {
        const showUserAnswer = answer.key === 'other' && userAnswer;
        const trimmedUserAnswer = userAnswer.trim();

        const intakeFormValue = showUserAnswer ? `${answer.value}: ${trimmedUserAnswer}` : answer.value;

        saveAnswers({
            key: LocalStorageKeys[RoutePath.ImportantEvent],
            itemQuestion: { question: t('important-event-title'), value: intakeFormValue },
        });

        pageConfiguration.handleOnPageComplete({
            key: answer.key,
            value: intakeFormValue,
        });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>{t('important-event-title')}</Typography>

            <Typography variant={TypographyVariants.h2}>
                {t('important-event-subtitle')}
            </Typography>

            {events.map((v: ChoiceType) => (
                <ChoiceButton
                    item={v.key === 'other' ? { userAnswer, setUserAnswer, ...v } : v}
                    key={v.key}
                    center={true}
                    selected={v.key === importantEvent}
                    typographyText={t(v.value)}
                    onClick={() => handleChoiceButtonClick(v)}
                    pageValue={importantEvent}
                />
            ))}

            {importantEvent === 'other' &&
                <FloatedButton floated={importantEvent.length} withGradient>
                    <NextButton onClick={() => handleClick(otherOptionAnswer)} disabled={false} />
                </FloatedButton>
            }
        </>
    );
};

