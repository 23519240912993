import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

interface RadioButtonProps {
    title: string;
    description: string;
    checked: boolean;
    onClick: () => void;
    effectivePrice: number;
}

export const RadioButton: FC<RadioButtonProps> = ({
    title,
    description,
    checked,
    onClick,
    effectivePrice,
}) => {
    const { radioButton, priceBlock, selected, radioSelector } = useStyles();

    return (
        <div className={`${radioButton} ${checked ? selected : ''}`} onClick={onClick}>
            <div>
                <Typography variant={TypographyVariants.h2}>{title}</Typography>
                <div className={priceBlock}>
                    <Typography variant={TypographyVariants.h3}>
                        <Trans
                            i18nKey="medication-program-radio-button-subscription-price"
                            values={{ effectivePrice }}
                        />
                    </Typography>
                </div>
            </div>
            <div>
                <Typography variant={TypographyVariants.h3}>{description}</Typography>
                <div className={radioSelector}>
                    <input type="radio" checked={checked} readOnly />
                    <span></span>
                </div>
            </div>
        </div>
    );
};
