export const RoutePath = {
    BodyGender: '/',
    LoseWeight: '/lose-weight',
    FamilyWeight: '/family-weight',
    FamilyWeightTip: '/family-weight-tip',
    PastAttempts: '/past-attempts',
    WeightLoss: '/weight-loss',
    LoseWeightReason: '/lose-weight-reason',
    WeightFactors: '/weight-factors',
    PrescriptionMedications: '/prescription-medications',
    PrescriptionMedicationsTip: '/prescription-medications-tip',
    BodyHeight: '/body-height',
    BodyWeight: '/body-weight',
    WeightMetabolicRate: '/weight-metabolic-rate',
    BodyIdealWeight: '/body-ideal-weight',
    BeforeAfterMedication: '/before-after-medication',
    HealthCondition: '/health-condition',
    MedicalCondition: '/medical-condition',
    MedicationIntake: '/medication-intake',
    Consent: '/consent',
    ScientificProof: '/scientific-proof',
    ImportantEvent: '/important-event',
    ImportantEventDate: '/important-event-date',
    Email: '/email',
    PlanLoader: '/plan-loader',
    AnalyzeResponses: '/analyze-responses',
    DeclineUser: '/without-medications',
    PrePaywall: '/pre-paywall',
    Medication: '/medication',
    Checkout: '/checkout',
    Congrats: '/congrats',
    Welcome: '/welcome',

    // Page for opening in WebView
    ShippingAddressEmbedded: '/shipping-address-embedded',
};
