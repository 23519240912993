import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        textAlign: 'center',

        '@media (min-width: 768px)' : {
            fontSize: '22px',
            lineHeight: '30px',
        }
    },
    subtitle: {
        textAlign: 'center',
        margin: '16px 0',
    },
    graphicImage: {
        margin: '24px 0 20px',
        width: '100%',
        maxWidth: '560px',
        height: 'auto',

        '@media (min-width: 768px)' : {
            margin: '24px 0 16px',
        }
    },
});
