import { Palette } from '../index';
import {ChoiceButtonStyles} from './choice-button';

export type MultiChoiceButtonType = {
    multiChoiceButton: {
        width: string,
        minWidth: string,
        height: string,
        padding: string,
        border: string,
        margin: string,
        background: Palette | string,
    },
    multiChoiceButtonCheckboxCheckmark: {
        border: string,
        backgroundColor: string,
        borderRadius: string,
        borderColor: string,
    },
    multiChoiceButtonSelected: {
        border: string,
        background: Palette | string,
        color: Palette | string,
    },
    multiChoiceButtonDisabled?: {
        color: string;
        background: string;
        cursor: string;
        pointerEvents: string;
    }
    multiChoiceButtonActive?: {
        boxShadow: string,
    },
    multiChoiceButtonHovered?: {
        background: string,
        color?: string,
    },
}

export const MultiChoiceButtonStyles: MultiChoiceButtonType = {
    multiChoiceButton: {
        ...ChoiceButtonStyles.choiceButton,
        background: '#E8F5FD',
    },
    multiChoiceButtonCheckboxCheckmark: {
        border: 'none',
        backgroundColor: '#1375D6',
        borderRadius: '2px',
        borderColor: 'inherit',
    },
    multiChoiceButtonSelected: {
        ...ChoiceButtonStyles.choiceButtonSelected,
        border: 'none',
        background: '#1375D6',
        color: '#FFFFFF'
    },
    multiChoiceButtonActive: {
        boxShadow: 'none',
    },
    multiChoiceButtonHovered: ChoiceButtonStyles.choiceButtonHovered,
    multiChoiceButtonDisabled: {
        color: '#808080',
        background: '#F6F6F6',
        cursor: 'none',
        pointerEvents: 'none'
    }
};
