import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    statesInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '12px',
        background: '#E8F5FD',
        borderRadius: '8px',
        padding: '16px',
        margin: '0 0 12px',

        '& h3': {
            textAlign: 'left',
            margin: 'unset',
            flexBasis: '95%',
        }
    },
    truncated: {

        '& h3': {
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    chevron: {
        display: 'flex',
        flexBasis: '2%',
        height: '16px',
        width: '16px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    icon: {
        content: '" "',
        borderBottom: '1.4px solid #010101',
        borderRight: '1.4px solid #010101',
        height: '10.4px',
        width: '10.4px',
        transform: 'rotate(45deg)',
        transition: 'transform 300ms ease',
        transformOrigin: 'center center',
    },
    rotate: {
        transform: 'rotate(225deg)',
        marginTop: '6.5px',
        transformOrigin: 'center center',
    },
    '@media only screen and (min-width: 768px)' : {
        statesInfoWrapper: {
            margin: '8px 0 16px'
        }
    }
});
