import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const MedicationProgramOptions: FC = () => {
    const { t } = useTranslation();

    const {
        medicationOptions,
        programItem,
    } = useStyles();

    const items = ['svg/medication/calendar.svg', 'svg/medication/refund.svg', 'svg/medication/medication-bottle.svg', 'svg/medication/delivery.svg'];
    const pageImages = useBackgroundImageService(items);

    return (
        <div className={medicationOptions}>
            <div className={programItem}>
                <img src={pageImages[0]} alt="Calendar"/>
                <Typography variant={TypographyVariants.h3}>
                    {t('medication-program-option-4')}
                </Typography>
            </div>
            <div className={programItem}>
                <img src={pageImages[1]} alt="Refund"/>
                <Typography variant={TypographyVariants.h3}>
                    {t('medication-program-option-5')}
                </Typography>
            </div>
            <div className={programItem}>
                <img src={pageImages[2]} alt="Medication Cost"/>
                <Typography variant={TypographyVariants.h3}>
                    {t('medication-program-option-6')}
                </Typography>
            </div>
            <div className={programItem}>
                <img src={pageImages[3]} alt="Delivery"/>
                <Typography variant={TypographyVariants.h3}>
                    {t('medication-program-option-7')}
                </Typography>
            </div>
        </div>
    );
};
