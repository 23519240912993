import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from '../typography';

import { useStyles } from './styles';

interface PaymentInfoProps {
    subscriptionPrice: number;
}

export const PaymentInfo: FC<PaymentInfoProps> = ({ subscriptionPrice }): JSX.Element => {
    const { infoText} = useStyles();

    return (
        <Typography className={infoText} variant={TypographyVariants.p}>
            <Trans i18nKey='discount-payment-info' values={{ subscriptionPrice }} />
        </Typography>
    )
}
