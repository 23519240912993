import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    accordionWrapper: { },
    blockTitle: {
        fontSize: '24px',
        lineHeight: '34px',
        textAlign: 'left',
        borderBottom: '1px solid #C5C5D1',
        margin: 'unset',
        padding: '32px 0'
    },
    subtitleClass: {
        fontWeight: 600,
        lineHeight: '22px',
        color: '#1375D6',
        margin: '24px auto',
        textAlign: 'left',
    },
    descriptionClass: {
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left',
        marginTop: '16px',
        whiteSpace: 'pre-line'
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    headTitle: {
        margin: 'unset',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        width: '75%',
    },
    chevron: {
        content: '" "',
        display: 'inline-block',
        borderBottom: '2px solid #000000',
        borderRight: '2px solid #000000',
        height: '16px',
        width: '16px',
        transform: 'rotate(45deg)',
        transition: 'transform 300ms ease',
        marginRight: '15px',
    },
    openChevron: {
        transform: 'rotate(225deg)',
        alignSelf: 'center'
    },
    body: {
        height: '0px',
        overflow: 'hidden',
        transition: 'height 300ms linear'
    },
    open: {
        height: 'auto',
        maxHeight: '500px',
        overflow: 'visible',
        transition: 'height 300ms linear'
    },
    itemWrapperClass: {
        borderBottom: '1px solid #C5C5D1',
        padding: '24px 0'
    },
    checkPointsListWrapper: {
        display: 'flex',
        gap: '12px',
        alignItems: 'flex-start',
        marginBottom: '16px',

        '&:last-of-type': {
            marginBottom: 0,
        }
    },
    checkPointText: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: 'unset',
        textAlign: 'unset',
    },
    '@media (min-width: 768px)': {
        blockTitle: {
            fontSize: '34px',
            lineHeight: '44px',
            textAlign: 'center',
            margin: '80px auto 24px',
        },
        headTitle: {
            fontSize: '24px',
            lineHeight: '34px',
        },
        subtitleClass: {
            fontSize: '24px',
            lineHeight: '34px',
        },
        itemWrapperClass: {

            '&:first-of-type': {
                padding: '0 0 24px'
            }
        },
        checkPointsListWrapper: {
            marginBottom: '24px'
        },
        checkPointText: {
            fontSize: '20px',
            lineHeight: '30px',
        },
        descriptionClass: {
            fontSize: '20px',
            lineHeight: '30px',
            marginTop: '24px',
        },
        chevron: {
            borderBottom: '3px solid #000000',
            borderRight: '3px solid #000000',
        }
    }
});
