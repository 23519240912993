import i18n from 'i18next';
import dayjs from 'dayjs';

import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANG = 'en';

export const DEFAULT_VARIABLES = {
    ableRXSema: 'Able RX Sema',
};

export const listOfTranslations = {
    'en': { translation: require('./en/en.json') },
    'fr': { translation: require('./fr/fr.json') },
    'fr-FR': { translation: require('./fr/fr.json') },
    'en-GB': { translation: require('./en_GB/en_GB.json') },
    'en-CA': { translation: require('./en_CA/en_CA.json') },
    'en-AU': { translation: require('./en_AU/en_AU.json') },
    'en-IN': { translation: require('./en_IN/en_IN.json') },
    'es': { translation: require('./es/es.json') },
};

const params = new URLSearchParams(document.location.search);
const language = params.get('language');

i18n.use(initReactI18next).init({
    supportedLngs: ['en', 'fr-FR', 'fr', 'en-GB', 'en-CA', 'en-AU', 'en-IN', 'es'],
    returnObjects: true,
    fallbackLng: DEFAULT_LANG,
    lng: language || window.navigator.language || window.navigator.userLanguage,
    resources: {
        ...listOfTranslations,
    },
    interpolation: {
        defaultVariables: {
            ...DEFAULT_VARIABLES,
            escapeValue: false,
        },
    },
    react: {
        transWrapTextNodes: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'em', 's', 'b'],
    },
    debug: false,
    returnEmptyString: false
});

require(`dayjs/locale/en.js`);

dayjs.locale(i18n.resolvedLanguage);

export default i18n;
