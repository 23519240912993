import React from 'react';
import { useNavigate } from 'react-router-dom';

import { isBoolean } from 'helpers';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { BackArrowButton } from 'components/back-arrow-button';

import { useStyles } from './styles';

export const EmailHeader = React.memo(({ classNames, backButtonAvailable }: any) => {

    const {
        header,
        backButton,
        backButtonWrapper,
        backButtonIconInnerWrapper,
        headerContent,
        headerAvatar,
        headerEmail
    } = useStyles();

    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    const isBackButtonAvailable = isBoolean(backButtonAvailable)
        ? backButtonAvailable
        : backButtonAvailable || true;

    const { pageValue: email } = useLocalStorage({ key: 'email', defaultValue: '' });

    const firstLetter = email ? email.split('')[0].toUpperCase() : '';

    return (
        <header className={`${header} ${classNames?.EmailHeaderHeader}`}>

            {isBackButtonAvailable && <BackArrowButton backButtonWrapper={backButtonWrapper}
                                                       backButtonIconInnerWrapper={backButtonIconInnerWrapper}
                                                       backButton={backButton} handleBack={handleBack} />
            }

            <div className={`${headerContent} ${classNames?.EmailHeaderContent}`}>
                <div className={`${headerAvatar} ${classNames?.EmailHeaderHeaderAvatar}`}>{firstLetter}</div>
                <Typography variant={TypographyVariants.h3}
                            className={`${headerEmail} ${classNames?.EmailHeaderHeaderEmail}`}>
                    {email}
                </Typography>
            </div>

            {isBackButtonAvailable && <div className={backButtonWrapper}></div>}
        </header>
    );
});
