import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    title: {
        lineHeight: '24px',
        textAlign: 'left',
        margin: '32px 0 16px',
    },
    continueButton: {
        ...nextButton.nextButton,
        background: '#36B992',

        '&:active': {
            ...nextButton.nextButtonFocus,
            background: '#2B9475',
        },

        '&:disabled': {
            ...nextButton.nextButtonDisabled,
        },

        '&:hover': {
            background: '#31A783',
        },
    },
    smallerMargin: {
        margin: '20px 0 16px'
    },
    '@media (min-width: 768px)' : {
        title: {
            margin: '40px 0 24px',
        },
        smallerMargin: {
            margin: '24px 0 16px'
        }
    },
}));
