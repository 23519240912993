import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles } from './styles';

export const ScientificProof = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const {
        title,
        graphicImage,
        infoBlocksWrapper,
        infoBlock,
        infoText
    } = useStyles();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const items = [
        'svg/scientific-proof/scientific-proof-graphic.svg',
        'svg/scientific-proof/columbia-university.svg',
        'svg/scientific-proof/mayo-clinic.svg'
    ];
    const pageImages = useBackgroundImageService(items);

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey='scientific-proof-title' />
            </Typography>

            <img src={pageImages[0]} className={graphicImage} alt='Scientific Proof Graphic' />

            <div className={infoBlocksWrapper}>
                <div className={infoBlock}>
                    <img src={pageImages[1]} alt='Columbia University Badge' />
                    <Typography variant={TypographyVariants.h3} className={infoText}>
                        <Trans i18nKey='scientific-proof-columbia-university-description' components={[<strong></strong>]} />
                    </Typography>
                </div>
                <div className={infoBlock}>
                    <img src={pageImages[2]} alt='The Mayo Clinic Badge' />
                    <Typography variant={TypographyVariants.h3} className={infoText}>
                        <Trans i18nKey='scientific-proof-mayo-clinic-description' components={[<strong></strong>]} />
                    </Typography>
                </div>
            </div>

            <NextButton typographyText={t('scientific-proof-button-next')} onClick={handleClick} />
        </>
    );
};
