import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    infoText: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        color: '#999999',
        margin: 0
    }
});
