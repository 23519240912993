import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    programWrapper: {
        background: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 16px',
        marginTop: '24px',
    },
    programTitle: {
        lineHeight: '24px',
        textAlign: 'left',
        margin: '12px 0'
    },
    medicationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 0 0',
        gap: '24px',
        borderTop: '1px solid #C5C5D1',

        '& img': {
            width: '100%',
            height: '168px',
            borderRadius: '8px',
            objectFit: 'cover',
            marginBottom: '24px'
        }
    },
    programItemBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
    },
    programItem: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px',

        '& h3': {
            textAlign: 'inherit',
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
    circle: {
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        background: '#1375D6',
        color: '#FFFFFF',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        justifyContent: 'center',
        flexShrink: 0,
    },
    sustainabilityInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '8px',
        padding: '16px',
        background: '#E8F5FD',
        borderRadius: '8px',

        '& > h3:first-child': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
        },

        '& > h3': {
            textAlign: 'left',
            margin: 'unset'
        }
    },
    medicationProgramSummaryWrapper: {
        margin: '24px 0 0',

        '& > p': {
            margin: '16px 0'
        },

        '& > div:last-child': {
            margin: '0 0 8px'
        }
    },
    '@media (min-width: 768px)': {
        programWrapper: {
            padding: '24px',
            marginTop: '32px',
        },
        programTitle: {
            margin: 'unset',
            fontSize: '34px',
            lineHeight: '44px',
            textAlign: 'left',
        },
        medicationWrapper: {
            borderTop: 'none',
            gap: '32px',
            padding: '24px 0 32px',
            borderBottom: '1px solid #C5C5D1',

            '& > div:first-child': {
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '40px',
            },

            '& img': {
                width: '272px',
                height: '194px',
                marginBottom: 0
            }
        },
        programItemBlock: {
            gap: '24px',
        },
        programItem: {

            '& h3': {
                fontSize: '20px',
                lineHeight: '26px'
            }
        },
        medicationProgramSummaryWrapper: {
            margin: '32px 0 0',

            '& > div > h1': {
                margin: '16px 0 0'
            },

            '& > div:last-child': {
                margin: 0
            }
        },
    }
});
