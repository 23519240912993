import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    agreementText: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        margin: '32px 0 12px',

        '& a': {
            color: '#1375D6'
        },

        '@media (min-width: 768px)' : {
            margin: '40px 0 16px'
        }
    }
});
