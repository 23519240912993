import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: 600,
    },
    graphicImage: {
        width: '100%',
        maxWidth: '560px',
        height: 'auto',
    },
    infoBlocksWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#F6F6F6',
        borderRadius: '8px',
        padding: '16px',
        margin: '40px auto 10px'
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '32px',

        '&:last-child': {
            marginBottom: 0,
        }
    },
    infoText: {
        textAlign: 'left',
        marginLeft: '16px'
    },
    '@media (min-width: 768px)': {
        title: {
            fontSize: '28px',
            lineHeight: '33px',
            margin: '80px auto 40px'
        },
        infoBlocksWrapper: {
            margin: '47px auto 20px'
        },
        infoText: {
            fontSize: '16px',
            lineHeight: '24px',
        },
    }
});
