import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    tipPageWrapper: {
        background: '#F6F6F6',
    },
    childrenWrapper: {
        maxWidth: '608px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0px 16px 0px',
        margin: '0px auto',
        overflowX: 'hidden',
        position: 'relative'
    },
    faqWrapper: {
        marginTop: '32px',
        marginBottom: '20px',
    },
    medicationProgramSelectorWrapper: {
        marginBottom: '32px',

        '& > div > h1': {
            margin: 0
        }
    },
    medicationSummaryWrapper: {
        marginTop: '4px',

        '& > div:first-child': {
            margin: '0 0 32px'
        },

        '& > p': {
            margin: '16px 0'
        },

        '& > div:last-child': {
            margin: '0 0 24px'
        }
    },
    getPlanButton: {
        ...nextButton.nextButton,
        margin: 0,
        background: '#36B992',

        '&:active': {
            ...nextButton.nextButtonFocus,
            background: '#2B9475',
        },
    },
    '@media (min-width: 768px)': {
        childrenWrapper: {
            maxWidth: '800px'
        },
        faqWrapper: {
            marginBottom: '96px',
        },
        medicationProgramSelectorWrapper: {
            marginBottom: '80px',

            '& > div > h1': {
                margin: '-24px 0 0'
            }
        },
        medicationSummaryWrapper: {
            marginTop: 0,

            '& > div:first-child': {
                margin: '16px 0 40px'
            },

            '& > div:last-child': {
                margin: 0
            }
        },
        getPlanButton: {
            margin: 0,
        },
    },
    '@media (hover: hover)': {
        getPlanButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
                background: '#31A783',
            }
        }
    }
}));
