export const MAX_PERCENTS = 100;

export const LOADER_COLORS = {
    bodyColor: '#EB731A',
    bodyColorCheckIconPathFill: '#FFDDBC',
    habitColor: '#1375D6',
    habitColorCheckIconPathFill: '#D1DCF8',
    nutritionColor: '#36B992',
    nutritionColorCheckIconPathFill: '#CBEBE1',
};
