import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        display: 'inline-block',
        margin: '-26px 0 12px 0',

        '@media (min-width: 768px)' : {
            margin: '0 0 24px 0'
        }
    },
    listStyle: {
        listStyle: 'disc',
        listStylePosition: 'outside',
        margin: '8px 0 0 25px',

        '& li': {
            marginBottom: '8px'
        },

        '& li::marker': {
            fontSize: '15px',
            verticalAlign: 'top'
        },

        '@media (min-width: 768px)' : {
            margin: '24px 0 0 25px',

            '& li:last-of-type': {
                marginBottom: '56px'
            },
        }
    }
});
