import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { isNextButtonDisabled } from 'helpers/get-multichoice-button-select-options';

import { UserEligibilityKeys } from 'constants/user-eligibility';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const MedicalCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicalCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: cardiovascularDiseaseUserAnswer, setPageValue: setCardiovascularDiseaseUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-cardiovascular-disease-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: neurologicalDiseaseUserAnswer, setPageValue: setNeurologicalDiseaseUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-neurological-disease-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: psychiatricDiseaseUserAnswer, setPageValue: setPsychiatricDiseaseUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-psychiatric-disease-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: autoimmuneDiseaseUserAnswer, setPageValue: setAutoimmuneDiseaseUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-autoimmune-disease-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: eatingDisorderUserAnswer, setPageValue: setEatingDisorderUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-eating-disorder-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: otherGIDiseasesUserAnswer, setPageValue: setOtherGIDiseasesUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-other-gi-diseases-userAnswer`,
        defaultValue: '',
    });

    const { pageValue: otherUserAnswer, setPageValue: setOtherUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicalCondition]}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoicesControl: MultiChoiceType[] = [
        {
            key: 'diabetes',
            value: t('medical-condition-option-diabetes')
        },
        {
            key: 'high-blood-pressure',
            value: t('medical-condition-option-high-blood-pressure')
        },
        {
            key: 'high-cholesterol',
            value: t('medical-condition-option-high-cholesterol')
        },
        {
            key: 'gallbladder-disease',
            value: t('medical-condition-option-gallbladder-disease')
        },
        {
            key: 'liver-disease',
            value: t('medical-condition-option-fatty-liver-disease')
        },
        {
            key: 'malabsorption-syndrome',
            value: t('medical-condition-option-malabsorption-syndrome')
        },
        {
            key: 'none',
            value: t('medical-condition-option-none'),
        }
    ];

    const multiChoicesExp: MultiChoiceType[] = [
        {
            key: 'insulin-dependent-diabetes',
            value: t('medical-condition-option-insulin-dependent-diabetes')
        },
        {
            key: 'non-insulin-dependent-diabetes',
            value: t('medical-condition-option-non-insulin-dependent-diabetes')
        },
        {
            key: 'high-blood-pressure',
            value: t('medical-condition-option-high-blood-pressure')
        },
        {
            key: 'high-cholesterol',
            value: t('medical-condition-option-high-cholesterol')
        },
        {
            key: 'gallbladder-disease',
            value: t('medical-condition-option-gallbladder-disease')
        },
        {
            key: 'liver-disease',
            value: t('medical-condition-option-liver-disease')
        },
        {
            key: 'cardiovascular-disease',
            value: t('medical-condition-option-cardiovascular-disease'),
            userAnswer: cardiovascularDiseaseUserAnswer,
            setUserAnswer: setCardiovascularDiseaseUserAnswer,
        },
        {
            key: 'neurological-disease',
            value: t('medical-condition-option-neurological-disease'),
            userAnswer: neurologicalDiseaseUserAnswer,
            setUserAnswer: setNeurologicalDiseaseUserAnswer,
        },
        {
            key: 'psychiatric-disease',
            value: t('medical-condition-option-psychiatric-disease'),
            userAnswer: psychiatricDiseaseUserAnswer,
            setUserAnswer: setPsychiatricDiseaseUserAnswer,
        },
        {
            key: 'autoimmune-disease',
            value: t('medical-condition-option-autoimmune-disease'),
            userAnswer: autoimmuneDiseaseUserAnswer,
            setUserAnswer: setAutoimmuneDiseaseUserAnswer,
        },
        {
            key: 'kidney-disease',
            value: t('medical-condition-option-kidney-disease')
        },
        {
            key: 'eating-disorder',
            value: t('medical-condition-option-eating-disorder'),
            userAnswer: eatingDisorderUserAnswer,
            setUserAnswer: setEatingDisorderUserAnswer,
        },
        {
            key: 'inflammatory-bowel-disease',
            value: t('medical-condition-option-inflammatory-bowel-disease')
        },
        {
            key: 'other-gi-diseases',
            value: t('medical-condition-option-other-gi-diseases'),
            userAnswer: otherGIDiseasesUserAnswer,
            setUserAnswer: setOtherGIDiseasesUserAnswer,
        },
        {
            key: 'other',
            value: t('medical-condition-option-other'),
            userAnswer: otherUserAnswer,
            setUserAnswer: setOtherUserAnswer,
        },
        {
            key: 'none',
            value: t('medical-condition-option-none'),
        }
    ];

    const sm_medical_director_changes = localStorage.getItem(ExperimentsKeys.sm_medical_director_changes) === '1';
    const multiChoices = sm_medical_director_changes ? multiChoicesExp : multiChoicesControl;

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return (currentOption?.value === 'none' || currentOption?.userAnswer === undefined) ? currentOption?.value : `${currentOption?.value}: ${currentOption?.userAnswer?.trim()}`;
        });

        let userStatus = pageValue[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicationIntakePageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntake]) as string);
        if (medicationIntakePageValue?.length > 0 && medicationIntakePageValue[0] !== 'none' && userStatus === UserEligibilityKeys.userStatus.approved) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('medical-condition-title'),
                value: pageValue[0] === 'none'
                    ?
                    multiChoices
                        .filter(multiChoice => multiChoice.key !== 'none')
                        .map(multiChoice => `"${multiChoice.value}" - NO`)
                    :
                    selectOption
            },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus }
        });

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('medical-condition-title')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    textAreaInputLabel={'medical-condition-textarea-label'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={isNextButtonDisabled(pageValue, multiChoices)} />
            </FloatedButton>
        </>
    );
};
