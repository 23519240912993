import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';
import { getMultiChoiceButtonSelectOptions } from 'helpers/get-multichoice-button-select-options';

export const LoseWeightReason = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.LoseWeightReason];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.LoseWeightReason]}-other-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: MultiChoiceType[] = [
        { key: 'increase-self-confidence', value: t('lose-weight-reason-option-increase-self-confidence') },
        { key: 'feel-better-in-clothes', value: t('lose-weight-reason-option-feel-better-in-clothes') },
        { key: 'have-more-energy', value: t('lose-weight-reason-option-have-more-energy') },
        { key: 'look-more-attractive', value: t('lose-weight-reason-option-look-more-attractive') },
        { key: 'enhance-physical-performance', value: t('lose-weight-reason-option-enhance-physical-performance') },
        { key: 'disease-risk', value: t('lose-weight-reason-option-reduce-disease-risk') },
        { key: 'other', value: t('lose-weight-reason-option-other'), userAnswer, setUserAnswer },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('lose-weight-reason-title'), value: selectOption },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim()
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {t('lose-weight-reason-title')}
            </Typography>
            <Typography variant={TypographyVariants.h2}>{t('lose-weight-reason-subtitle')}</Typography>
            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    textAreaInputLabel={'lose-weight-reason-text-area-input-label'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
